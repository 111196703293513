import * as constants from '../constants';

export function reloadApp() {
    return {
        type: constants.RELOAD_APP,
    };
};

export function authenticate(email, password) {
    return {
        type: constants.AUTHENTICATE,
        payload: {email, password}
    };
}

export function deauthenticate(email, password) {
    return {
        type: constants.DEAUTHENTICATE,
    };
}

export function authenticateDone(data) {
    return {
        type: constants.AUTHENTICATE_DONE,
        payload: {data}
    };
}

export function deauthenticateDone() {
    return {
        type: constants.DEAUTHENTICATE_DONE,
    };
}

export function authenticateError(errorMessage) {
    return {
        type: constants.AUTHENTICATE_ERROR,
        payload: {errorMessage}
    };
}

export function setAuthToken(token, update_store=true) {
    return {
        type: constants.SET_AUTH_TOKEN,
        payload: {token, update_store}
    };
};

export function createAccount(email, password, password_confirmation) {
    return {
        type: constants.CREATE_ACCOUNT,
        payload: {email, password, password_confirmation}
    };
}

export function createAccountDone(success, message) {
    return {
        type: constants.CREATE_ACCOUNT_DONE,
        payload: {success, message,}
    };
}

export function createAccountError(error) {
    return {
        type: constants.CREATE_ACCOUNT_ERROR,
        payload: {error}
    };
}

export function clearRegistration() {
    return {
        type: constants.CLEAR_REGISTRATION,
    };
}

export function sendConfirmation(token) {
    return {
        type: constants.SEND_CONFIRMATION,
        payload: {
            token,
        }
    };
}

export function sendConfirmationDone(success, message) {
    return {
        type: constants.SEND_CONFIRMATION_DONE,
        payload: {success, message}
    };
}

export function sendConfirmationError(error) {
    return {
        type: constants.SEND_CONFIRMATION_ERROR,
        payload: {error}
    };
}

export function sendReconfirmation() {
    return {
        type: constants.SEND_RECONFIRMATION,
    };
}

export function sendReconfirmationDone(success, message) {
    return {
        type: constants.SEND_RECONFIRMATION_DONE,
        payload: {success, message}
    };
}

export function sendReconfirmationError(error) {
    return {
        type: constants.SEND_RECONFIRMATION_ERROR,
        payload: {error}
    };
}

export function showToast(body, title) {
    const key = Math.random().toString(16).slice(2);
    return {
        type: constants.SHOW_TOAST,
        payload: {body, title, key}
    };
}

export function showModal(body, title, actions) {
    const key = Math.random().toString(16).slice(2);
    return {
        type: constants.SHOW_MODAL,
        payload: {body, title, actions, key}
    };
}

export function clearAllToasts() {
    return {
        type: constants.CLEAR_ALL_TOASTS,
    };
}

export function clearAllModals() {
    return {
        type: constants.CLEAR_ALL_MODALS,
    };
}

export function clearToast(key) {
    return {
        type: constants.CLEAR_TOAST,
        payload: {key}
    };
}

export function clearHeadModal() {
    return {
        type: constants.CLEAR_HEAD_MODAL,
    };
}

export function fetchProfile(slug) {
    return {
        type: constants.FETCH_PROFILE,
        payload: {slug}
    };
}

export function fetchProfileDone(slug, data) {
    return {
        type: constants.FETCH_PROFILE_DONE,
        payload: {
            slug, data,
        }
    };
}

export function fetchProfileLoading(slug) {
    return {
        type: constants.FETCH_PROFILE_LOADING,
        payload: {
            slug,
        }
    };
}

export function fetchProfileError(slug, error) {
    return {
        type: constants.FETCH_PROFILE_ERROR,
        payload: {
            slug, error,
        }
    };
}

export function saveProfile(data) {
    return {
        type: constants.SAVE_PROFILE,
        payload: {
            slug: data.get('slug'),
            data: data.deleteAll(['error', 'loading', 'slug', 'url',
                'profile_image_url', 'email']),
        }
    };
}

export function saveProfileOngoing(slug) {
    return {
        type: constants.SAVE_PROFILE_ONGOING,
        payload: {slug}
    };
}

export function saveProfileError(slug, error) {
    return {
        type: constants.SAVE_PROFILE_ERROR,
        payload: {slug, error}
    };
}

export function saveProfileDone(slug, newProfileData) {
    return {
        type: constants.SAVE_PROFILE_DONE,
        payload: {slug, data: newProfileData}
    };
}

export function fetchPages() {
    return {
        type: constants.FETCH_PAGES,
    };
}

export function fetchPagesDone(data) {
    return {
        type: constants.FETCH_PAGES_DONE,
        payload: data,
    };
}

export function savePage(slug, page) {
    return {
        type: constants.SAVE_PAGE,
        payload: {slug, page},
    };
}

export function savePageDone(slug, page) {
    return {
        type: constants.SAVE_PAGE_DONE,
        payload: {slug, page},
    };
}

export function createNewPage() {
    return {
        type: constants.CREATE_NEW_PAGE,
    }
}

export function fetchPagesError(error) {
    return {
        type: constants.FETCH_PAGES_ERROR,
        payload: {error},
    };
}

export function fetchOnlineUsers() {
    return {
        type: constants.FETCH_ONLINE_USERS
    };
}

export function fetchOnlineUsersDone(userlist) {
    return {
        type: constants.FETCH_ONLINE_USERS_DONE,
        payload: userlist,
    };
}

export function fetchOnlineUsersError(error) {
    return {
        type: constants.FETCH_ONLINE_USERS_ERROR,
        payload: {
            error
        }
    };
}

export function connectWs(token) {
    return {
        type: constants.WS_CONNECT,
        payload: {token}
    };
}

export function disconnectWs() {
    return {
        type: constants.WS_DISCONNECT,
    };
}

export function fetchConversationsDone(conversations) {
    return {
        type: constants.FETCH_CONVERSATIONS_DONE,
        payload: {conversations}
    };
}

export function setChatOnline(value) {
    return {
        type: constants.SET_CHAT_ONLINE,
        payload: { value }
    };
}

export function sendChatMessage(text, params, transitKey=null) {
    return {
        type: constants.SEND_CHAT_MESSAGE,
        payload: {
            conversation: params.conversationId,
            text, transitKey,
        }
    };
}

export function storeTransitMessage(conversationId, key, text) {
    return {
        type: constants.STORE_TRANSIT_MESSAGE,
        payload: {
            key, text, conversationId,
        }
    };
}

export function openChatConversation(slug) {
    return {
        type: constants.OPEN_CHAT_CONVERSATION,
        payload: {slug}
    };
}

export function closeChatConversation(slug) {
    return {
        type: constants.CLOSE_CHAT_CONVERSATION,
        payload: {slug}
    };
}

export function syncMessages(conversationId, messages) {
    return {
        type: constants.SYNC_MESSAGES,
        payload: {conversationId, messages}
    };
}

export function syncNewMessage(conversationId, message, transitKey) {
    return {
        type: constants.SYNC_NEW_MESSAGE,
        payload: {conversationId, message, transitKey}
    };
}

export function syncConversationOpen(conversationId, conversation) {
    return {
        type: constants.SYNC_CONVERSATION_OPEN,
        payload: {conversationId, conversation}
    };
}

export function createConversation(message, {recipient}) {
    return {
        type: constants.CREATE_CHAT_CONVERSATION,
        payload: {
            message,
            recipient,
        }
    };
}

export function searchUsers(query, distance=10, locId) {
    return {
        type: constants.SEARCH_USERS,
        payload: {query, distance, locId}
    };
}

export function searchUsersOnline(query) {
    return {
        type: constants.SEARCH_USERS_ONLINE,
        payload: {query}
    };
}

export function searchUsersNearby(query, distance=50, latitude=null, longitude=null) {
    return {
        type: constants.SEARCH_USERS,
        payload: {
            query,
            distance,
            nearby: {
                lat: latitude,
                lon: longitude,
            }
        }
    };
}

export function searchUsersDone(users) {
    return {
        type: constants.SEARCH_USERS_DONE,
        payload: {users}
    };
}

export function searchUsersClear() {
    return {
        type: constants.SEARCH_USERS_CLEAR,
    };
}

export function searchUsersError(error) {
    return {
        type: constants.SEARCH_USERS_ERROR,
        payload: {error},
    };
}

export function searchGroups(query) {
    return {
        type: constants.SEARCH_GROUPS,
        payload: {query}
    };
}

export function searchGroupsDone(groups) {
    return {
        type: constants.SEARCH_GROUPS_DONE,
        payload: {groups}
    };
}

export function searchGroupsClear() {
    return {
        type: constants.SEARCH_GROUPS_CLEAR,
    };
}

export function searchGroupsError(error) {
    return {
        type: constants.SEARCH_GROUPS_ERROR,
        payload: {error},
    };
}

export function updateLastSeenMessage(conversationId, msgId) {
    return {
        type: constants.UPDATE_LAST_SEEN_MESSAGE,
        payload: {conversationId, msgId},
    };
}

export function syncChatCountNotification(chatCount) {
    const a = {
        type: constants.SYNC_CHAT_COUNT_NOTIFICATION,
        payload: {chatCount}
    };
  return a;
}

export function syncModTasksCount(taskCount) {
    const a = {
        type: constants.SYNC_MOD_TASKS_COUNT,
        payload: {count: taskCount}
    };
  return a;
}

export function addQuotation() {
    return {
        type: constants.EDIT_QUOTATION,
        payload: {
            slug: '_new_',
        }
    };
}

export function editQuotation(slug) {
    return {
        type: constants.EDIT_QUOTATION,
        payload: {
            slug
        }
    };
}

export function deleteQuotation(slug) {
    return {
        type: constants.DELETE_QUOTATION,
        payload: {
            slug
        }
    };
}

export function deleteQuotationDone(slug) {
    return {
        type: constants.DELETE_QUOTATION_DONE,
        payload: {
            slug
        }
    };
}

export function saveQuotation(slug, quotation) {
    return {
        type: constants.SAVE_QUOTATION,
        payload: {
            slug, quotation,
        }
    };
}

export function saveQuotationOngoing() {
    return {
        type: constants.SAVE_QUOTATION_ONGOING,
    };
}

export function saveQuotationDone(slug, quotation) {
    return {
        type: constants.SAVE_QUOTATION_DONE,
        payload: {
            slug, quotation,
        }
    };
}

export function saveQuotationError(error) {
    return {
        type: constants.SAVE_QUOTATION_ERROR,
        payload: {
            error,
        }
    };
}

export function saveQuotationClear() {
    return {
        type: constants.SAVE_QUOTATION_CLEAR,
    };
}

export function fetchQuotations() {
    return {
        type: constants.FETCH_QUOTATIONS,
    };
}

export function fetchQuotationsLoading() {
    return {
        type: constants.FETCH_QUOTATIONS_LOADING,
    };
}

export function fetchQuotationsDone(quotations) {
    return {
        type: constants.FETCH_QUOTATIONS_DONE,
        payload: {
            result: quotations,
        }
    };
}

export function fetchQuotationsError(e) {
    return {
        type: constants.FETCH_QUOTATIONS_ERROR,
        payload: {error: e}
    };
}

export function deleteChat(slug) {
    return {
        type: constants.DELETE_CHAT,
        payload: {
            slug
        }
    };
}

export function redirectTo(location) {
    return {
        type: constants.REDIRECT_TO,
        payload: {location}
    };
}

export function fetchNextProfileComments(profile_slug) {
    return {
        type: constants.FETCH_NEXT_PROFILE_COMMENTS,
        payload: {slug: profile_slug}
    };
};

export function fetchNextProfileCommentsDone(profile_slug, comments, nextUrl, new_comments) {
    return {
        type: constants.FETCH_NEXT_PROFILE_COMMENTS_DONE,
        payload: {slug: profile_slug, comments, nextUrl, new_comments}
    };
};

export function fetchNextProfileCommentsError(profile_slug, error) {
    return {
        type: constants.FETCH_NEXT_PROFILE_COMMENTS_ERROR,
        payload: {slug: profile_slug, error}
    };
};

export function clearProfileComments(profile_slug) {
    return {
        type: constants.CLEAR_PROFILE_COMMENTS,
        payload: {slug: profile_slug}
    };
};

export function sendProfileComment(profile_slug, comment) {
    return {
        type: constants.SEND_PROFILE_COMMENT,
        payload: {slug: profile_slug, comment}
    };
};

export function sendProfileCommentDone(profile_slug, comment) {
    return {
        type: constants.SEND_PROFILE_COMMENT_DONE,
        payload: {slug: profile_slug, comment}
    };
};

export function sendProfileCommentError(profile_slug, error) {
    return {
        type: constants.SEND_PROFILE_COMMENT_ERROR,
        payload: {slug: profile_slug, error}
    };
};

export function saveGroup(group) {
    return {
        type: constants.SAVE_GROUP,
        payload: {group}
    };
}

export function clearNewGroup() {
    return {
        type: constants.CLEAR_NEW_GROUP,
    };
}

export function saveGroupDone(group) {
    return {
        type: constants.SAVE_GROUP_DONE,
        payload: {group}
    };
}

export function saveGroupError(error) {
    return {
        type: constants.SAVE_GROUP_ERROR,
        payload: {error}
    };
}

export function fetchGroup(slug) {
    return {
        type: constants.FETCH_GROUP,
        payload: {slug}
    };
}

export function fetchGroupDone(slug, data) {
    return {
        type: constants.FETCH_GROUP_DONE,
        payload: {
            slug, data,
        }
    };
}

export function fetchGroupLoading(slug) {
    return {
        type: constants.FETCH_GROUP_LOADING,
        payload: {
            slug,
        }
    };
}

export function fetchGroupError(slug, error) {
    return {
        type: constants.FETCH_GROUP_ERROR,
        payload: {
            slug, error,
        }
    };
}

export function deleteGroup(slug) {
    return {
        type: constants.DELETE_GROUP,
        payload: { slug }
    };
}

export function removeConversation(slug) {
    return {
        type: constants.REMOVE_CONVERSATION,
        payload: { slug }
    };
}

export function deleteGroupDone(slug) {
    return {
        type: constants.DELETE_GROUP_DONE,
        payload: { slug }
    };
}

export function deleteGroupError(slug, error) {
    return {
        type: constants.DELETE_GROUP_ERROR,
        payload: { slug, error, }
    };
}

export function fetchNextGroupComments(groupSlug) {
    return {
        type: constants.FETCH_NEXT_GROUP_COMMENTS,
        payload: {groupSlug}
    };
};

export function fetchNextGroupCommentsDone(groupSlug, comments, nextUrl, new_comments) {
    return {
        type: constants.FETCH_NEXT_GROUP_COMMENTS_DONE,
        payload: {groupSlug, comments, nextUrl, new_comments}
    };
};

export function fetchNextGroupCommentsError(groupSlug, error) {
    return {
        type: constants.FETCH_NEXT_GROUP_COMMENTS_ERROR,
        payload: {groupSlug, error}
    };
};

export function clearGroupComments(groupSlug) {
    return {
        type: constants.CLEAR_GROUP_COMMENTS,
        payload: {groupSlug}
    };
};

export function sendGroupComment(groupSlug, comment) {
    return {
        type: constants.SEND_GROUP_COMMENT,
        payload: {groupSlug, comment}
    };
};

export function sendGroupCommentDone(groupSlug, comment) {
    return {
        type: constants.SEND_GROUP_COMMENT_DONE,
        payload: {groupSlug, comment}
    };
};

export function sendGroupCommentError(groupSlug, error) {
    return {
        type: constants.SEND_GROUP_COMMENT_ERROR,
        payload: {groupSlug, error}
    };
};

export function deleteGroupComment(groupSlug, slug) {
    return {
        type: constants.DELETE_GROUP_COMMENT,
        payload: {slug, groupSlug}
    };
}

export function deleteGroupCommentDone(groupSlug, slug, comment) {
    return {
        type: constants.DELETE_GROUP_COMMENT_DONE,
        payload: {slug, groupSlug, comment}
    };
}

export function deleteGroupCommentError(groupSlug, slug, error) {
    return {
        type: constants.DELETE_GROUP_COMMENT_ERROR,
        payload: {slug, groupSlug, error}
    };
}

export function fetchModTasks() {
    return {
        type: constants.FETCH_MOD_TASKS,
    };
};

export function fetchModTasksLoading() {
    return {
        type: constants.FETCH_MOD_TASKS_LOADING,
    };
}

export function fetchModTasksDone(tasks) {
    return {
        type: constants.FETCH_MOD_TASKS_DONE,
        payload: {
            result: tasks,
        }
    };
}

export function fetchModTask(slug) {
    return {
        type: constants.FETCH_MOD_TASK,
        payload: {
            slug,
        }
    };
};

export function fetchModTaskDone(slug, task) {
    return {
        type: constants.FETCH_MOD_TASK_DONE,
        payload: {
            slug, task,
        }
    };
}

export function fetchModTasksError(e) {
    return {
        type: constants.FETCH_MOD_TASKS_ERROR,
        payload: {error: e}
    };
}

export function takeModTasks(taskSlugs) {
    return {
        type: constants.SEND_MOD_TASKS_ACTION,
        payload: {action: 'take', slugs: taskSlugs}
    };
}

export function sendModTasksAction(taskSlugs, actionName, extra={}) {
    return {
        type: constants.SEND_MOD_TASKS_ACTION,
        payload: {action: actionName, slugs: taskSlugs, extra}
    };
}

export function sendModTasksActionDone(response) {
    return {
        type: constants.SEND_MOD_TASKS_ACTION_DONE,
        payload: {result: response},
    };
};

export function fetchInitialData() {
    return {
        type: constants.FETCH_INITIAL_DATA,
    };
}

export function fetchInitialDataDone(data) {
    return {
        type: constants.FETCH_INITIAL_DATA_DONE,
        payload: {data}
    };
}

export function fetchInitialDataError(error) {
    return {
        type: constants.FETCH_INITIAL_DATA_ERROR,
        payload: {error}
    };
}

export function hideLoading() {
    return {
        type: constants.HIDE_LOADING,
    };
}

export function openNavigationEdit() {
    return {
        type: constants.OPEN_NAVIGATION_EDIT,
    };
}

export function openNavigationEditDone(entries) {
    return {
        type: constants.OPEN_NAVIGATION_EDIT_DONE,
        payload: {entries}
    };
}

export function saveNavigationEdit() {
    return {
        type: constants.SAVE_NAVIGATION_EDIT,
    };
}

export function saveNavigationEditDone(entries) {
    return {
        type: constants.SAVE_NAVIGATION_EDIT_DONE,
        payload: {entries},
    };
}

export function saveNavigationEditError(error) {
    return {
        type: constants.SAVE_NAVIGATION_EDIT_ERROR,
        payload: {error},
    };
}

export function cancelNavigationEdit() {
    return {
        type: constants.CANCEL_NAVIGATION_EDIT,
    };
}

export function moveNavigationItem(oldPos, newPos, location) {
    return {
        type: constants.MOVE_NAVIGATION_ITEM,
        payload: {oldPos, newPos, location},
    };
}

export function changeNavigationItem(pos, item, location) {
    return {
        type: constants.CHANGE_NAVIGATION_ITEM,
        payload: {pos, item, location},
    };
}

export function newNavigationItem(location) {
    return {
        type: constants.NEW_NAVIGATION_ITEM,
        payload: {location},
    };
}

export function openPageEdit(slug){
    return {
        type: constants.OPEN_PAGE_EDIT,
        payload: {slug},
    };
};

export function openPageEditDone(slug, page){
    return {
        type: constants.OPEN_PAGE_EDIT_DONE,
        payload: {slug, page},
    };
};

export function openPageEditError(slug, error){
    return {
        type: constants.OPEN_PAGE_EDIT_ERROR,
        payload: {slug, error},
    };
};

export function addNewPageBlock(blockPath=[]) {
    return {
        type: constants.ADD_NEW_PAGE_BLOCK,
        payload: {blockPath},
    };
};

export function insertNewPageAboveBlock(blockPath) {
    return {
        type: constants.INSERT_NEW_PAGE_ABOVE_BLOCK,
        payload: {blockPath},
    };
};

export function insertNewPageBelowBlock(blockPath) {
    return {
        type: constants.INSERT_NEW_PAGE_BELOW_BLOCK,
        payload: {blockPath},
    };
};

export function deletePageBlock(blockPath) {
    return {
        type: constants.DELETE_PAGE_BLOCK,
        payload: {blockPath},
    };
};

export function undeletePageBlock(blockPath) {
    return {
        type: constants.UNDELETE_PAGE_BLOCK,
        payload: {blockPath},
    };
};

export function movePageBlock(oldPos, newPos, blockPath=[]) {
    return {
        type: constants.MOVE_PAGE_BLOCK,
        payload: {oldPos, newPos, blockPath},
    };
}

export function setPageBlockContent(blockPath, content) {
    return {
        type: constants.SET_PAGE_BLOCK_CONTENT,
        payload: {blockPath, content},
    };
};

export function setPageBlockProps(blockPath, props) {
    return {
        type: constants.SET_PAGE_BLOCK_PROPS,
        payload: {blockPath, props},
    };
};

export function setPageEditPage(page) {
    return {
        type: constants.SET_PAGE_EDIT_PAGE,
        payload: {page},
    };
};

export function savePageEdit() {
    return {
        type: constants.SAVE_PAGE_EDIT,
    };
}

export function savePageEditDone(page) {
    return {
        type: constants.SAVE_PAGE_EDIT_DONE,
        payload: {page},
    };
}

export function savePageEditError(error) {
    return {
        type: constants.SAVE_PAGE_EDIT_ERROR,
        payload: {error},
    };
}

export function deletePage(slug) {
    return {
        type: constants.DELETE_PAGE,
        payload: {slug},
    };
}

export function deletePageDone(slug) {
    return {
        type: constants.DELETE_PAGE_DONE,
        payload: {slug},
    };
}

export function cancelPageEdit() {
    return {
        type: constants.CANCEL_PAGE_EDIT,
    };
}

export function clearPageEditError() {
    return {
        type: constants.CLEAR_PAGE_EDIT_ERROR,
    };
}

export function fetchUserEdit() {
    return {
        type: constants.FETCH_USER_EDIT,
    };
}

export function fetchUserEditDone(users) {
    return {
        type: constants.FETCH_USER_EDIT_DONE,
        payload: {users}
    };
}

export function fetchUserEditError(error) {
    return {
        type: constants.FETCH_USER_EDIT_ERROR,
        payload: {error}
    };
}

export function sendUserInvitation(user) {
    return {
        type: constants.SEND_USER_INVITATION,
        payload: {user},
    };
}

export function sendUserInvitationDone(success, message) {
    return {
        type: constants.SEND_USER_INVITATION_DONE,
        payload: {success, message},
    };
}

export function sendUserInvitationError(error) {
    return {
        type: constants.SEND_USER_INVITATION_ERROR,
        payload: {error},
    };
}

export function acceptInvitation(token, nickname, password, password_confirmation) {
    return {
        type: constants.ACCEPT_INVITATION,
        payload: {
            token,
            nickname,
            password,
            password_confirmation
        },
    };
}

export function acceptInvitationDone(success, message) {
    return {
        type: constants.ACCEPT_INVITATION_DONE,
        payload: {success, message},
    };
}

export function acceptInvitationError(error) {
    return {
        type: constants.ACCEPT_INVITATION_ERROR,
        payload: {error},
    };
}

export function deleteUser(slug, params={}) {
    return {
        type: constants.DELETE_USER,
        payload: {slug, params},
    };
};

export function deleteUserDone(success, message) {
    return {
        type: constants.DELETE_USER_DONE,
        payload: {success, message},
    };
};

export function deleteUserError(error) {
    return {
        type: constants.DELETE_USER_ERROR,
        payload: {error},
    };
};

export function deleteOwnAccount() {
    return {
        type: constants.DELETE_OWN_ACCOUNT,
    };
};

export function deleteOwnAccountDone(success, message) {
    return {
        type: constants.DELETE_OWN_ACCOUNT_DONE,
        payload: {success, message},
    };
};

export function deleteOwnAccountError(error) {
    return {
        type: constants.DELETE_OWN_ACCOUNT_ERROR,
        payload: {error},
    };
};

export function fetchModReviews() {
    return {
        type: constants.FETCH_MOD_REVIEWS,
    };
};

export function fetchModReviewsDone(reviews) {
    return {
        type: constants.FETCH_MOD_REVIEWS_DONE,
        payload: {
            result: reviews,
        }
    };
}

export function fetchModReviewsError(e) {
    return {
        type: constants.FETCH_MOD_REVIEWS_ERROR,
        payload: {error: e}
    };
}

export function fetchModReview(slug) {
    return {
        type: constants.FETCH_MOD_REVIEW,
        payload: {
            slug,
        }
    };
};

export function fetchModReviewDone(review) {
    return {
        type: constants.FETCH_MOD_REVIEW_DONE,
        payload: {
            review,
        }
    };
}

export function fetchModReviewError(error) {
    return {
        type: constants.FETCH_MOD_REVIEW_ERROR,
        payload: {error}
    };
}

export function searchGeolocations(query) {
    return {
        type: constants.SEARCH_GEOLOCATIONS,
        payload: {query}
    };
}

export function searchGeolocationsDone(locations) {
    return {
        type: constants.SEARCH_GEOLOCATIONS_DONE,
        payload: {locations}
    };
}

export function searchGeolocationsError(error) {
    return {
        type: constants.SEARCH_GEOLOCATIONS_ERROR,
        payload: {error}
    };
}

export function searchGeolocationsClear(error) {
    return {
        type: constants.SEARCH_GEOLOCATIONS_CLEAR
    };
}

export function fetchProfileImages() {
    return {
        type: constants.FETCH_PROFILE_IMAGES
    };
}

export function fetchProfileImagesDone(images, currentImageId, uploadQuota) {
    return {
        type: constants.FETCH_PROFILE_IMAGES_DONE,
        payload: {
            images, currentImageId, uploadQuota,
        }
    };
}

export function fetchProfileImagesError(error) {
    return {
        type: constants.FETCH_PROFILE_IMAGES_ERROR,
        payload: {error}
    };
}

export function deleteProfileImage(sid) {
    return {
        type: constants.DELETE_PROFILE_IMAGE,
        payload: {
            sid
        }
    };
}

export function deleteProfileImageDone(sid, uploadQuota) {
    return {
        type: constants.DELETE_PROFILE_IMAGE_DONE,
        payload: {
            sid, uploadQuota,
        }
    };
}

export function deleteProfileImageError(error, uploadQuota) {
    return {
        type: constants.DELETE_PROFILE_IMAGE_ERROR,
        payload: {error, uploadQuota,}
    };
}

export function deleteProfileComment(profileSlug, slug) {
    return {
        type: constants.DELETE_PROFILE_COMMENT,
        payload: {slug, profileSlug}
    };
}

export function deleteProfileCommentDone(profileSlug, slug, comment) {
    return {
        type: constants.DELETE_PROFILE_COMMENT_DONE,
        payload: {slug, profileSlug, comment}
    };
}

export function deleteProfileCommentError(profileSlug, slug, error) {
    return {
        type: constants.DELETE_PROFILE_COMMENT_ERROR,
        payload: {slug, profileSlug, error}
    };
}

export function fetchUserAccountData() {
    return {
        type: constants.FETCH_USER_ACCOUNT_DATA,
    };
};

export function fetchUserAccountDataDone(data) {
    return {
        type: constants.FETCH_USER_ACCOUNT_DATA_DONE,
        payload: {data},
    };
};

export function fetchUserAccountDataError(error) {
    return {
        type: constants.FETCH_USER_ACCOUNT_DATA_ERROR,
        payload: {error},
    };
};

export function changePassword(oldpass, newpass, newpassconfirm) {
    return {
        type: constants.CHANGE_PASSWORD,
        payload: {oldpass, newpass, newpassconfirm},
    };
}

export function changePasswordDone() {
    return {
        type: constants.CHANGE_PASSWORD_DONE,
    };
}

export function changePasswordError(error) {
    return {
        type: constants.CHANGE_PASSWORD_ERROR,
        payload: {error},
    };
}

export function resetUserAccountPasswordData() {
    return {
        type: constants.RESET_USER_ACCOUNT_PASSWORD_DATA,
    };
};

export function saveUserAccountData(data) {
    return {
        type: constants.SAVE_USER_ACCOUNT_DATA,
        payload: {data}
    };
}

export function saveUserAccountDataError(error) {
    return {
        type: constants.SAVE_USER_ACCOUNT_DATA_ERROR,
        payload: {error}
    };
}

export function saveUserAccountDataDone(data) {
    return {
        type: constants.SAVE_USER_ACCOUNT_DATA_DONE,
        payload: {data}
    };
}

export function sendResetPasswordInstructions(email) {
    return {
        type: constants.SEND_RESET_PASSWORD_INSTRUCTIONS,
        payload: {email}
    };
};

export function sendResetPasswordInstructionsDone(message) {
    return {
        type: constants.SEND_RESET_PASSWORD_INSTRUCTIONS_DONE,
        payload: {message},
    };
};

export function sendResetPasswordInstructionsError(error) {
    return {
        type: constants.SEND_RESET_PASSWORD_INSTRUCTIONS_ERROR,
        payload: {error}
    };
};

export function clearResetPassword() {
    return {
        type: constants.CLEAR_RESET_PASSWORD
    };
};

export function sendNewPassword(token, password, password_confirmation) {
    return {
        type: constants.SEND_NEW_PASSWORD,
        payload: {token, password, password_confirmation}
    };
};

export function sendNewPasswordDone(message, auth) {
    return {
        type: constants.SEND_NEW_PASSWORD_DONE,
        payload: {message, auth,}
    };
};

export function sendNewPasswordError(error) {
    return {
        type: constants.SEND_NEW_PASSWORD_ERROR,
        payload: {error}
    };
};

export function subscribe_initialize() {
    return {
        type: constants.SUBSCRIBE_INITIALIZE,
        payload: {}
    };
};

export function subscribe_initialized(success, options) {
    return {
        type: constants.SUBSCRIBE_INITIALIZED,
        payload: {success, options}
    };
};

export function subscribe_chooseSubscription(subscription) {
    return {
        type: constants.SUBSCRIBE_CHOOSE_SUBSCRIPTION,
        payload: {subscription}
    };
};

export function subscribe_chooseSubscriptionDone(success, message) {
    return {
        type: constants.SUBSCRIBE_CHOOSE_SUBSCRIPTION_DONE,
        payload: {success, message,}
    };
}

export function subscribe_choosePaymentMethod(payment_method, address) {
    return {
        type: constants.SUBSCRIBE_CHOOSE_PAYMENTMETHOD,
        payload: {payment_method, address}
    };
}

export function subscribe_choosePaymentMethodDone(success, message) {
    return {
        type: constants.SUBSCRIBE_CHOOSE_PAYMENTMETHOD_DONE,
        payload: {success, message,}
    };
}

export function subscribe_fillAddress() {
    return {
        type: constants.SUBSCRIBE_FILL_ADDRESS,
        payload: {}
    };
};

export function subscribe_paydirektInitialize(subscription, address) {
    const payment_paydirekt = true;
    return {
        type: constants.SUBSCRIBE_PAYDIREKT_INITIALIZE,
        payload: {subscription, payment_paydirekt, address}
    };
};

export function subscribe_paydirektInitialized(success, redirect_url) {
    if(success) {
        return {
            type: constants.SUBSCRIBE_PAYDIREKT_INITIALIZED,
            payload: {success, redirect_url,}
        };
    }
    else {
        return {
            type: constants.SUBSCRIBE_ERROR,
            payload: {error: true}
        };
    }
};

export function subscribe_payPalInitialize(subscription) {
    const payment_paypal = true;
    return {
        type: constants.SUBSCRIBE_PAYPAL_INITIALIZE,
        payload: {subscription, payment_paypal,}
    };
};

export function subscribe_payPalInitialized(success, redirect_url) {
    if(success) {
        return {
            type: constants.SUBSCRIBE_PAYPAL_INITIALIZED,
            payload: {success, redirect_url,}
        };
    }
    else {
        return {
            type: constants.SUBSCRIBE_ERROR,
            payload: {error: true}
        };
    }
};

export function clearSubscribe() {
    return {
        type: constants.CLEAR_SUBSCRIBE,
    };
};
export function fetchProfileEdit() {
    return {
        type: constants.FETCH_PROFILE_EDIT,
    };
};

export function fetchProfileEditDone(result) {
    return {
        type: constants.FETCH_PROFILE_EDIT_DONE,
        payload: {result}
    };
};

export function fetchProfileEditError(error) {
    return {
        type: constants.FETCH_PROFILE_EDIT_ERROR,
        payload: {error}
    };
};

export function saveProfileEdit(data) {
    return {
        type: constants.SAVE_PROFILE_EDIT,
        payload: {
            data: data.deleteAll(['slug', 'url', 'profile_image_url', 'email']),
        }
    };
}

export function saveProfileEditError(error) {
    return {
        type: constants.SAVE_PROFILE_EDIT_ERROR,
        payload: {error}
    };
}

export function saveProfileEditDone(result) {
    return {
        type: constants.SAVE_PROFILE_EDIT_DONE,
        payload: {result}
    };
}
