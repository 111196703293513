import {BLOCK_TYPE, INLINE_STYLE, ENTITY_TYPE} from "draftail";
// TODO: add kd_ prefix via namespace helper

export const REGULAR_MODE = "kd_regular_view_mode";
export const EDIT_NAVIGATION_MODE = "kd_edit_navigation_view_mode";
export const EDIT_PAGE_MODE = "kd_edit_page_view_mode";

export const FETCH_INITIAL_DATA = 'kd_fetch_initial_data';
export const FETCH_INITIAL_DATA_DONE = 'kd_fetch_initial_data_done';
export const FETCH_INITIAL_DATA_ERROR = 'kd_fetch_initial_data_error';
export const HIDE_LOADING = 'kd_hide_loading';
export const SHOW_TOAST = 'kd_show_toast';
export const SHOW_MODAL = 'kd_show_modal';
export const RELOAD_APP = 'kd_reload_app';
export const CLEAR_ALL_TOASTS = 'kd_clear_all_toasts';
export const CLEAR_ALL_MODALS = 'kd_clear_all_modals';
export const CLEAR_TOAST = 'kd_clear_toast';
export const CLEAR_HEAD_MODAL = 'kd_clear_head_modal';
export const AUTHENTICATE = 'kd_authenticate';
export const DEAUTHENTICATE = 'kd_deauthenticate';
export const AUTHENTICATE_DONE = 'kd_authenticate_done';
export const DEAUTHENTICATE_DONE = 'kd_deauthenticate_done';
export const AUTHENTICATE_ERROR = 'kd_authenticate_error';
export const SET_AUTH_TOKEN = 'kd_set_auth_token';
export const CREATE_ACCOUNT = 'kd_create_account';
export const CREATE_ACCOUNT_DONE = 'kd_create_account_done';
export const CREATE_ACCOUNT_ERROR = 'kd_create_account_error';
export const CLEAR_REGISTRATION = 'kd_clear_registration';
export const SEND_CONFIRMATION = 'kd_send_confirmation';
export const SEND_CONFIRMATION_DONE = 'kd_send_confirmation_done';
export const SEND_CONFIRMATION_ERROR = 'kd_send_confirmation_error';
export const SEND_RECONFIRMATION = 'kd_send_reconfirmation';
export const SEND_RECONFIRMATION_DONE = 'kd_send_reconfirmation_done';
export const SEND_RECONFIRMATION_ERROR = 'kd_send_reconfirmation_error';
export const GET_PROFILE_DONE = 'kd_get_profile_done';
export const FETCH_PROFILE = 'kd_fetch_profile';
export const FETCH_PROFILE_DONE = 'kd_fetch_profile_done';
export const FETCH_PROFILE_LOADING = 'kd_fetch_profile_loading';
export const FETCH_PROFILE_ERROR = 'kd_fetch_profile_error';
export const SAVE_PROFILE = 'kd_save_profile';
export const SAVE_PROFILE_ONGOING = 'kd_save_profile_ongoing';
export const SAVE_PROFILE_DONE = 'kd_save_profile_done';
export const SAVE_PROFILE_ERROR = 'kd_save_profile_error';
export const FETCH_PROFILE_IMAGES = 'kd_fetch_profile_images';
export const FETCH_PROFILE_IMAGES_DONE = 'kd_fetch_profile_images_done';
export const FETCH_PROFILE_IMAGES_ERROR = 'kd_fetch_profile_images_error';
export const DELETE_PROFILE_IMAGE = 'kd_delete_profile_image';
export const DELETE_PROFILE_IMAGE_DONE = 'kd_delete_profile_image_done';
export const DELETE_PROFILE_IMAGE_ERROR = 'kd_delete_profile_image_error';
export const DELETE_PROFILE_COMMENT = 'kd_delete_profile_comment';
export const DELETE_PROFILE_COMMENT_DONE = 'kd_delete_profile_comment_done';
export const DELETE_PROFILE_COMMENT_ERROR = 'kd_delete_profile_comment_error';
export const FETCH_PAGES = 'kd_fetch_pages';
export const FETCH_PAGES_DONE = 'kd_fetch_pages_done';
export const FETCH_PAGES_ERROR = 'kd_fetch_pages_error';
export const SAVE_PAGE = 'kd_save_page';
export const SAVE_PAGE_DONE = 'kd_save_page_done';
export const SAVE_PAGE_ERROR = 'kd_save_page_error';
export const CREATE_NEW_PAGE = 'kd_create_new_page';
export const FETCH_ONLINE_USERS = 'kd_fetch_online_users';
export const FETCH_ONLINE_USERS_DONE = 'kd_fetch_online_users_done';
export const FETCH_ONLINE_USERS_LOADING = 'kd_fetch_online_users_loading';
export const FETCH_ONLINE_USERS_ERROR = 'kd_fetch_online_users_error';
export const SEARCH_USERS = 'kd_search_users';
export const SEARCH_USERS_ONLINE = 'kd_search_users_online';
export const SEARCH_USERS_DONE = 'kd_search_users_done';
export const SEARCH_USERS_CLEAR = 'kd_search_users_clear';
export const SEARCH_USERS_ERROR = 'kd_search_users_error';
export const SEARCH_GROUPS = 'kd_search_groups';
export const SEARCH_GROUPS_DONE = 'kd_search_groups_done';
export const SEARCH_GROUPS_CLEAR = 'kd_search_groups_clear';
export const SEARCH_GROUPS_ERROR = 'kd_search_groups_error';
export const EDIT_QUOTATION = 'kd_edit_quotation';
export const DELETE_QUOTATION = 'kd_delete_quotation';
export const DELETE_QUOTATION_DONE = 'kd_delete_quotation_done';
export const SAVE_QUOTATION = 'kd_save_quotation';
export const SAVE_QUOTATION_DONE = 'kd_save_quotation_done';
export const SAVE_QUOTATION_ONGOING = 'kd_save_quotation_ongoing';
export const SAVE_QUOTATION_ERROR = 'kd_save_quotation_error';
export const SAVE_QUOTATION_CLEAR = 'kd_save_quotation_clear';
export const FETCH_QUOTATIONS = 'kd_fetch_quotations';
export const FETCH_QUOTATIONS_LOADING = 'kd_fetch_quotations_loading';
export const FETCH_QUOTATIONS_DONE = 'kd_fetch_quotations_done';
export const FETCH_QUOTATIONS_ERROR = 'kd_fetch_quotations_error';
export const DELETE_CHAT = 'kd_delete_chat';
export const FETCH_NEXT_PROFILE_COMMENTS = 'kd_fetch_next_profile_comments';
export const FETCH_NEXT_PROFILE_COMMENTS_DONE = 'kd_fetch_next_profile_comments_done';
export const FETCH_NEXT_PROFILE_COMMENTS_ERROR = 'kd_fetch_next_profile_comments_error';
export const CLEAR_PROFILE_COMMENTS = 'kd_clear_profile_comments';
export const SEND_PROFILE_COMMENT = 'kd_send_profile_comment';
export const SEND_PROFILE_COMMENT_DONE = 'kd_send_profile_comment_done';
export const SEND_PROFILE_COMMENT_ERROR = 'kd_send_profile_comment_error';
export const FETCH_GROUP = 'kd_fetch_group';
export const FETCH_GROUP_DONE = 'kd_fetch_group_done';
export const FETCH_GROUP_LOADING = 'kd_fetch_group_loading';
export const FETCH_GROUP_ERROR = 'kd_fetch_group_error';
export const SAVE_GROUP = 'kd_save_group';
export const CLEAR_NEW_GROUP = 'kd_clear_new_group';
export const SAVE_GROUP_DONE = 'kd_save_group_done';
export const SAVE_GROUP_ERROR = 'kd_save_group_error';
export const DELETE_GROUP = 'kd_delete_group';
export const REMOVE_CONVERSATION = 'kd_remove_conversation';
export const DELETE_GROUP_DONE = 'kd_delete_group_done';
export const DELETE_GROUP_ERROR = 'kd_delete_group_error';
export const FETCH_NEXT_GROUP_COMMENTS = 'kd_fetch_next_group_comments';
export const FETCH_NEXT_GROUP_COMMENTS_DONE = 'kd_fetch_next_group_comments_done';
export const FETCH_NEXT_GROUP_COMMENTS_ERROR = 'kd_fetch_next_group_comments_error';
export const CLEAR_GROUP_COMMENTS = 'kd_clear_group_comments';
export const SEND_GROUP_COMMENT = 'kd_send_group_comment';
export const SEND_GROUP_COMMENT_DONE = 'kd_send_group_comment_done';
export const SEND_GROUP_COMMENT_ERROR = 'kd_send_group_comment_error';
export const DELETE_GROUP_COMMENT = 'kd_delete_group_comment';
export const DELETE_GROUP_COMMENT_DONE = 'kd_delete_group_comment_done';
export const DELETE_GROUP_COMMENT_ERROR = 'kd_delete_group_comment_error';
export const REDIRECT_TO = 'kd_redirect_to';
export const FETCH_MOD_TASKS = 'kd_fetch_mod_tasks';
export const FETCH_MOD_TASKS_LOADING = 'kd_fetch_mod_tasks_loading';
export const FETCH_MOD_TASKS_ERROR = 'kd_fetch_mod_tasks_error';
export const FETCH_MOD_TASKS_DONE = 'kd_fetch_mod_tasks_done';
export const FETCH_MOD_TASK = 'kd_fetch_mod_task';
export const FETCH_MOD_TASK_DONE = 'kd_fetch_mod_task_done';
export const SEND_MOD_TASKS_ACTION = 'kd_send_mod_tasks_action';
export const SEND_MOD_TASKS_ACTION_DONE = 'kd_send_mod_tasks_action_done';
export const OPEN_NAVIGATION_EDIT = 'kd_open_navigation_edit';
export const OPEN_NAVIGATION_EDIT_DONE = 'kd_open_navigation_edit_done';
export const SAVE_NAVIGATION_EDIT = 'kd_save_navigation_edit';
export const SAVE_NAVIGATION_EDIT_DONE = 'kd_save_navigation_edit_done';
export const SAVE_NAVIGATION_EDIT_ERROR = 'kd_save_navigation_edit_error';
export const CANCEL_NAVIGATION_EDIT = 'kd_cancel_navigation_edit';
export const MOVE_NAVIGATION_ITEM = 'kd_move_navigation_item';
export const CHANGE_NAVIGATION_ITEM = 'kd_change_navigation_item';
export const NEW_NAVIGATION_ITEM = 'kd_new_navigation_item';
export const OPEN_PAGE_EDIT = 'kd_open_page_edit';
export const OPEN_PAGE_EDIT_DONE = 'kd_open_page_edit_done';
export const OPEN_PAGE_EDIT_ERROR = 'kd_open_page_edit_error';
export const ADD_NEW_PAGE_BLOCK = 'kd_add_new_page_block';
export const INSERT_NEW_PAGE_BELOW_BLOCK = 'kd_insert_new_page_below_block';
export const INSERT_NEW_PAGE_ABOVE_BLOCK = 'kd_insert_new_page_above_block';
export const DELETE_PAGE_BLOCK = 'kd_delete_page_block';
export const UNDELETE_PAGE_BLOCK = 'kd_undelete_page_block';
export const DELETE_PAGE = 'kd_delete_page';
export const DELETE_PAGE_DONE = 'kd_delete_page_done';
export const MOVE_PAGE_BLOCK = 'kd_move_page_block';
export const SET_PAGE_BLOCK_CONTENT = 'kd_set_page_block_content';
export const SET_PAGE_BLOCK_PROPS = 'kd_set_page_block_props';
export const SET_PAGE_EDIT_PAGE = 'kd_set_page_edit_page';
export const SAVE_PAGE_EDIT = 'kd_save_page_edit';
export const SAVE_PAGE_EDIT_DONE = 'kd_save_page_edit_done';
export const SAVE_PAGE_EDIT_ERROR = 'kd_save_page_edit_error';
export const CLEAR_PAGE_EDIT_ERROR = 'kd_clear_page_edit_error';
export const CANCEL_PAGE_EDIT = 'kd_cancel_page_edit';
export const FETCH_USER_EDIT = 'kd_fetch_user_edit';
export const FETCH_USER_EDIT_DONE = 'kd_fetch_user_edit_done';
export const FETCH_USER_EDIT_ERROR = 'kd_fetch_user_edit_error';
export const SEND_USER_INVITATION = 'kd_send_user_invitation';
export const SEND_USER_INVITATION_DONE = 'kd_send_user_invitation_done';
export const SEND_USER_INVITATION_ERROR = 'kd_send_user_invitation_error';
export const ACCEPT_INVITATION = 'kd_accept_invitation';
export const ACCEPT_INVITATION_DONE = 'kd_accept_invitation_done';
export const ACCEPT_INVITATION_ERROR = 'kd_accept_invitation_error';
export const DELETE_USER = 'kd_delete_user';
export const DELETE_USER_DONE = 'kd_delete_user_done';
export const DELETE_USER_ERROR = 'kd_delete_user_error';
export const FETCH_MOD_REVIEWS = 'kd_fetch_mod_reviews';
export const FETCH_MOD_REVIEWS_DONE = 'kd_fetch_mod_reviews_done';
export const FETCH_MOD_REVIEWS_ERROR = 'kd_fetch_mod_reviews_error';
export const FETCH_MOD_REVIEW = 'kd_fetch_mod_review';
export const FETCH_MOD_REVIEW_DONE = 'kd_fetch_mod_review_done';
export const FETCH_MOD_REVIEW_ERROR = 'kd_fetch_mod_review_error';
export const SEARCH_GEOLOCATIONS = 'kd_search_geolocations';
export const SEARCH_GEOLOCATIONS_DONE = 'kd_search_geolocations_done';
export const SEARCH_GEOLOCATIONS_ERROR = 'kd_search_geolocations_error';
export const SEARCH_GEOLOCATIONS_CLEAR = 'kd_search_geolocations_clear';
export const STORE_TRANSIT_MESSAGE = 'kd_store_transit_message';
export const FETCH_USER_ACCOUNT_DATA = 'kd_fetch_user_account_data';
export const FETCH_USER_ACCOUNT_DATA_DONE = 'kd_fetch_user_account_data_done';
export const FETCH_USER_ACCOUNT_DATA_ERROR = 'kd_fetch_user_account_data_error';
export const DELETE_OWN_ACCOUNT = 'kd_delete_own_account';
export const DELETE_OWN_ACCOUNT_DONE = 'kd_delete_own_account_done';
export const DELETE_OWN_ACCOUNT_ERROR = 'kd_delete_own_account_error';
export const CHANGE_PASSWORD = 'kd_change_password';
export const CHANGE_PASSWORD_DONE = 'kd_change_password_done';
export const CHANGE_PASSWORD_ERROR = 'kd_change_password_error';
export const RESET_USER_ACCOUNT_PASSWORD_DATA = 'kd_reset_user_account_password_data';
export const SAVE_USER_ACCOUNT_DATA = 'kd_save_user_account_data';
export const SAVE_USER_ACCOUNT_DATA_DONE = 'kd_save_user_account_done';
export const SAVE_USER_ACCOUNT_DATA_ERROR = 'kd_save_user_account_error';
export const SEND_RESET_PASSWORD_INSTRUCTIONS = 'kd_send_reset_password_instructions';
export const SEND_RESET_PASSWORD_INSTRUCTIONS_DONE = 'kd_send_reset_password_instructions_done';
export const SEND_RESET_PASSWORD_INSTRUCTIONS_ERROR = 'kd_send_reset_password_instructions_error';
export const CLEAR_RESET_PASSWORD = 'kd_clear_password_reset';
export const SEND_NEW_PASSWORD = 'kd_send_new_password';
export const SEND_NEW_PASSWORD_DONE = 'kd_send_new_password_done';
export const SEND_NEW_PASSWORD_ERROR = 'kd_send_new_password_error';
export const SUBSCRIBE_INITIALIZE = 'kd_subscribe_initialize';
export const SUBSCRIBE_INITIALIZED = 'kd_subscribe_initialized';
export const SUBSCRIBE_FILL_ADDRESS = 'kd_subscribe_fill_address';
export const SUBSCRIBE_FILL_ADDRESS_DONE = 'kd_subscribe_fill_address_done';
export const SUBSCRIBE_CHOOSE_SUBSCRIPTION = 'kd_subscribe_choose_subscription';
export const SUBSCRIBE_CHOOSE_SUBSCRIPTION_DONE = 'kd_subscribe_choose_subscription_done';
export const SUBSCRIBE_CHOOSE_PAYMENTMETHOD = 'kd_subscribe_choose_paymentmethod';
export const SUBSCRIBE_CHOOSE_PAYMENTMETHOD_DONE = 'kd_subscribe_choose_paymentmethod_done';
export const SUBSCRIBE_PAYPAL_INITIALIZE = 'kd_subscribe_paypal_initialize';
export const SUBSCRIBE_PAYPAL_INITIALIZED = 'kd_subscribe_paypal_initialized';
export const SUBSCRIBE_PAYDIREKT_INITIALIZE = 'kd_subscribe_paydirekt_initialize';
export const SUBSCRIBE_PAYDIREKT_INITIALIZED = 'kd_subscribe_paydirekt_initialized';
export const SUBSCRIBE_DONE = 'kd_subscribe_done';
export const SUBSCRIBE_ERROR = 'kd_subscribe_error';
export const CLEAR_SUBSCRIBE = 'kd_clear_subscribe';
export const FETCH_PROFILE_EDIT = 'kd_fetch_profile_edit';
export const FETCH_PROFILE_EDIT_DONE = 'kd_fetch_profile_edit_done';
export const FETCH_PROFILE_EDIT_ERROR = 'kd_fetch_profile_edit_error';
export const SAVE_PROFILE_EDIT = 'kd_save_profile_edit';
export const SAVE_PROFILE_EDIT_DONE = 'kd_save_profile_edit_done';
export const SAVE_PROFILE_EDIT_ERROR = 'kd_save_profile_edit_error';

// websockets
export const WS_CONNECT = 'kd_ws_connect';
export const WS_DISCONNECT = 'kd_ws_disconnect';
export const FETCH_CONVERSATIONS = 'kd_fetch_conversations';
export const FETCH_CONVERSATIONS_DONE = 'kd_fetch_conversations_done';
export const SEND_CHAT_MESSAGE = 'kd_send_chat_message';
export const OPEN_CHAT_CONVERSATION = 'kd_open_chat_conversation';
export const CLOSE_CHAT_CONVERSATION = 'kd_close_chat_conversation';
export const SET_CHAT_ONLINE = 'kd_set_chat_online';
export const SYNC_MESSAGES = 'kd_sync_messages';
export const SYNC_NEW_MESSAGE = 'kd_sync_new_message';
export const SYNC_CONVERSATION_OPEN = 'kd_sync_chat_open';
export const SYNC_CHAT_COUNT_NOTIFICATION = 'kd_sync_chat_count_notification';
export const SYNC_MOD_TASKS_COUNT = 'kd_sync_mod_tasks_count';
export const CREATE_CHAT_CONVERSATION = 'kd_create_chat_conversation';
export const UPDATE_LAST_SEEN_MESSAGE = 'kd_update_last_seen_message';

// ui
export const LOCATION_OPTIONS = {
    top:    {key: "t", text: 'Oben', value: 'top'},
    bottom: {key: "b", text: 'Unten', value: 'bottom'},
};

export const VISIBILITY_OPTIONS = {
    guest:   {key: "g", text: 'Gast', description: "nicht angemeldet", value: 'guest'},
    regular: {key: "r", text: 'Geist', description: "ohne Abo", value: 'regular'},
    active:  {key: "u", text: 'Aktiv', description: "mit Abo", value: 'active'},
    mod:     {key: "m", text: 'Moderator', value: 'mod'},
    admin:   {key: "a", text: 'Administrator', value: 'admin'},
};

//editor
export const EDITOR_BR_TYPE = "BR";
export const EDITOR_UNDO_TYPE = "undo";
export const EDITOR_REDO_TYPE = "redo";
export const EDITOR_LABELS = {};

EDITOR_LABELS[BLOCK_TYPE.UNSTYLED] = "P";
EDITOR_LABELS[BLOCK_TYPE.HEADER_ONE] = "H1";
EDITOR_LABELS[BLOCK_TYPE.HEADER_TWO] = "H2";
EDITOR_LABELS[BLOCK_TYPE.HEADER_THREE] = "H3";
EDITOR_LABELS[BLOCK_TYPE.HEADER_FOUR] = "H4";
EDITOR_LABELS[BLOCK_TYPE.HEADER_FIVE] = "H5";
EDITOR_LABELS[BLOCK_TYPE.HEADER_SIX] = "H6";
EDITOR_LABELS[BLOCK_TYPE.UNORDERED_LIST_ITEM] = "UL";
EDITOR_LABELS[BLOCK_TYPE.ORDERED_LIST_ITEM] = "OL";
EDITOR_LABELS[BLOCK_TYPE.CODE] = "{ }";
EDITOR_LABELS[BLOCK_TYPE.BLOCKQUOTE] = "❝";

EDITOR_LABELS[INLINE_STYLE.BOLD] = "B";
EDITOR_LABELS[INLINE_STYLE.ITALIC] = "𝘐";
EDITOR_LABELS[INLINE_STYLE.CODE] = "{ }";
EDITOR_LABELS[INLINE_STYLE.UNDERLINE] = "U";
EDITOR_LABELS[INLINE_STYLE.STRIKETHROUGH] = "S";
EDITOR_LABELS[INLINE_STYLE.MARK] = "☆";
EDITOR_LABELS[INLINE_STYLE.QUOTATION] = "❛";
EDITOR_LABELS[INLINE_STYLE.SMALL] = "𝖲𝗆a𝗅𝗅";
EDITOR_LABELS[INLINE_STYLE.SAMPLE] = "𝙳𝚊𝚝𝚊";
EDITOR_LABELS[INLINE_STYLE.INSERT] = "Ins";
EDITOR_LABELS[INLINE_STYLE.DELETE] = "Del";
EDITOR_LABELS[INLINE_STYLE.SUPERSCRIPT] = "Sup";
EDITOR_LABELS[INLINE_STYLE.SUBSCRIPT] = "Sub";
EDITOR_LABELS[INLINE_STYLE.KEYBOARD] = "⌘";

EDITOR_LABELS[ENTITY_TYPE.LINK] = "🔗";
EDITOR_LABELS[ENTITY_TYPE.IMAGE] = "🖼";
EDITOR_LABELS[ENTITY_TYPE.HORIZONTAL_RULE] = "―";
EDITOR_LABELS[EDITOR_BR_TYPE] = "↵";

EDITOR_LABELS[EDITOR_UNDO_TYPE] = "↺";
EDITOR_LABELS[EDITOR_REDO_TYPE] = "↻";

export const EDITOR_DESCRIPTIONS = {};

EDITOR_DESCRIPTIONS[BLOCK_TYPE.UNSTYLED] = "Paragraph";
EDITOR_DESCRIPTIONS[BLOCK_TYPE.HEADER_ONE] = "Heading 1";
EDITOR_DESCRIPTIONS[BLOCK_TYPE.HEADER_TWO] = "Heading 2";
EDITOR_DESCRIPTIONS[BLOCK_TYPE.HEADER_THREE] = "Heading 3";
EDITOR_DESCRIPTIONS[BLOCK_TYPE.HEADER_FOUR] = "Heading 4";
EDITOR_DESCRIPTIONS[BLOCK_TYPE.HEADER_FIVE] = "Heading 5";
EDITOR_DESCRIPTIONS[BLOCK_TYPE.HEADER_SIX] = "Heading 6";
EDITOR_DESCRIPTIONS[BLOCK_TYPE.UNORDERED_LIST_ITEM] = "Bulleted list";
EDITOR_DESCRIPTIONS[BLOCK_TYPE.ORDERED_LIST_ITEM] = "Numbered list";
EDITOR_DESCRIPTIONS[BLOCK_TYPE.BLOCKQUOTE] = "Blockquote";
EDITOR_DESCRIPTIONS[BLOCK_TYPE.CODE] = "Code block";

EDITOR_DESCRIPTIONS[INLINE_STYLE.BOLD] = "Bold";
EDITOR_DESCRIPTIONS[INLINE_STYLE.ITALIC] = "Italic";
EDITOR_DESCRIPTIONS[INLINE_STYLE.CODE] = "Code";
EDITOR_DESCRIPTIONS[INLINE_STYLE.UNDERLINE] = "Underline";
EDITOR_DESCRIPTIONS[INLINE_STYLE.STRIKETHROUGH] = "Strikethrough";
EDITOR_DESCRIPTIONS[INLINE_STYLE.MARK] = "Highlight";
EDITOR_DESCRIPTIONS[INLINE_STYLE.QUOTATION] = "Inline quotation";
EDITOR_DESCRIPTIONS[INLINE_STYLE.SMALL] = "Small";
EDITOR_DESCRIPTIONS[INLINE_STYLE.SAMPLE] = "Program output";
EDITOR_DESCRIPTIONS[INLINE_STYLE.INSERT] = "Inserted";
EDITOR_DESCRIPTIONS[INLINE_STYLE.DELETE] = "Deleted";
EDITOR_DESCRIPTIONS[INLINE_STYLE.KEYBOARD] = "Shortcut key";
EDITOR_DESCRIPTIONS[INLINE_STYLE.SUPERSCRIPT] = "Superscript";
EDITOR_DESCRIPTIONS[INLINE_STYLE.SUBSCRIPT] = "Subscript";

EDITOR_DESCRIPTIONS[ENTITY_TYPE.LINK] = "Link";
EDITOR_DESCRIPTIONS[ENTITY_TYPE.IMAGE] = "Image";
EDITOR_DESCRIPTIONS[ENTITY_TYPE.HORIZONTAL_RULE] = "Horizontal line";

EDITOR_DESCRIPTIONS[EDITOR_BR_TYPE] = "Line break";

EDITOR_DESCRIPTIONS[EDITOR_UNDO_TYPE] = "Undo";
EDITOR_DESCRIPTIONS[EDITOR_REDO_TYPE] = "Redo";

export const EDITOR_KEYBOARD_SHORTCUTS = {};

EDITOR_KEYBOARD_SHORTCUTS[BLOCK_TYPE.UNSTYLED] = "⌫";
EDITOR_KEYBOARD_SHORTCUTS[BLOCK_TYPE.HEADER_ONE] = "#";
EDITOR_KEYBOARD_SHORTCUTS[BLOCK_TYPE.HEADER_TWO] = "##";
EDITOR_KEYBOARD_SHORTCUTS[BLOCK_TYPE.HEADER_THREE] = "###";
EDITOR_KEYBOARD_SHORTCUTS[BLOCK_TYPE.HEADER_FOUR] = "####";
EDITOR_KEYBOARD_SHORTCUTS[BLOCK_TYPE.HEADER_FIVE] = "#####";
EDITOR_KEYBOARD_SHORTCUTS[BLOCK_TYPE.HEADER_SIX] = "######";
EDITOR_KEYBOARD_SHORTCUTS[BLOCK_TYPE.UNORDERED_LIST_ITEM] = "-";
EDITOR_KEYBOARD_SHORTCUTS[BLOCK_TYPE.ORDERED_LIST_ITEM] = "1.";
EDITOR_KEYBOARD_SHORTCUTS[BLOCK_TYPE.BLOCKQUOTE] = ">";
EDITOR_KEYBOARD_SHORTCUTS[BLOCK_TYPE.CODE] = "```";

EDITOR_KEYBOARD_SHORTCUTS[INLINE_STYLE.BOLD] = { other: "Ctrl + B", macOS: "⌘ + B" };
EDITOR_KEYBOARD_SHORTCUTS[INLINE_STYLE.ITALIC] = { other: "Ctrl + I", macOS: "⌘ + I" };
EDITOR_KEYBOARD_SHORTCUTS[INLINE_STYLE.UNDERLINE] = {
  other: "Ctrl + U",
  macOS: "⌘ + U",
};
EDITOR_KEYBOARD_SHORTCUTS[INLINE_STYLE.STRIKETHROUGH] = {
  other: "Ctrl + ⇧ + X",
  macOS: "⌘ + ⇧ + X",
};
EDITOR_KEYBOARD_SHORTCUTS[INLINE_STYLE.SUPERSCRIPT] = {
  other: "Ctrl + .",
  macOS: "⌘ + .",
};
EDITOR_KEYBOARD_SHORTCUTS[INLINE_STYLE.SUBSCRIPT] = {
  other: "Ctrl + ,",
  macOS: "⌘ + ,",
};

EDITOR_KEYBOARD_SHORTCUTS[ENTITY_TYPE.LINK] = { other: "Ctrl + K", macOS: "⌘ + K" };

EDITOR_KEYBOARD_SHORTCUTS[EDITOR_BR_TYPE] = "⇧ + ↵";
EDITOR_KEYBOARD_SHORTCUTS[ENTITY_TYPE.HORIZONTAL_RULE] = "- - -";

EDITOR_KEYBOARD_SHORTCUTS[EDITOR_UNDO_TYPE] = { other: "Ctrl + Z", macOS: "⌘ + Z" };
EDITOR_KEYBOARD_SHORTCUTS[EDITOR_REDO_TYPE] = { other: "Ctrl + ⇧ + Z", macOS: "⌘ + ⇧ + Z" };