import React, {useEffect, useCallback,} from 'react';
import {connect, useSelector, useDispatch} from "react-redux";
import Page from './Page';
import OnlineUsers from './OnlineUsers';
import { useHistory, } from 'react-router-dom';
import {
    isAuthed, getUserAccountData,
} from '../selectors';
import {
    fetchUserAccountData
} from '../actions';

import {
    Message, Button, Grid
} from "semantic-ui-react";

const LandingPage = ({}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const state = useSelector(getUserAccountData);
    const authed = useSelector(isAuthed);
    if(authed) {
        useEffect(() => {
            dispatch(fetchUserAccountData());
        }, []);
    }

    const isLoadingSubscriptionState = !state.get('role');
    const hasSubscription = !isLoadingSubscriptionState && ['active', 'mod', 'admin'].includes(state.get('role'))
    const onSubscribe = useCallback((ev, d) => {
        ev.preventDefault();
        history.push('/subscribe');
        return false;
    });

    return (<>
            {!isLoadingSubscriptionState && !hasSubscription && authed && <Message className="subscribe-infobox">
                <p>Mit einem Abo kannst du den kompletten Funktionsumfang nutzen.</p>
                <Button className="cuddle"
                        type="submit" data-cy="submit-notification-config"
                        onClick={onSubscribe}>
                        Jetzt Abo buchen
                </Button>
            </Message>}

            <Page slug="home" />
        </>
    );
};

LandingPage.defaultProps = {};
LandingPage.propTypes = {};

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
