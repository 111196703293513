import React, {useCallback, useEffect, useState} from 'react';
import Immutable from "immutable";
import { useSelector, useDispatch } from 'react-redux'
import {Link, useParams} from 'react-router-dom';

import {subscribe_initialize, subscribe_chooseSubscription, subscribe_choosePaymentMethod, subscribe_payPalInitialize, subscribe_paydirektInitialize, subscribe_fillAddress} from "../actions";
import {getSubscribe, getNavigationItems} from "../selectors";
import {isPresent, isDefined, fieldErr,} from "../helpers";

import {
    Grid, Header, Segment, Form, Button, Message, Dimmer, Loader, Checkbox, Transition, Table, Container
} from "semantic-ui-react";

const Countries = [
    { short: 'DE', long: 'Deutschland' },
    { short: 'AT', long: 'Österreich' },
    { short: 'LU', long: 'Luxemburg' },
    { short: 'CH', long: 'Schweiz' }
];

const SubscriptionChoiceForm = ({subscribe}) => {
    const [shakeToggler, setShakeToggler] = useState(true);
    const [termsAgreed, setTermsAgreed] = useState(false);

    const dispatch = useDispatch();
    const onChooseSubscription = useCallback((ev, subscriptionType) => {
        ev.preventDefault();
        if(termsAgreed) {
            if(!(subscriptionType >= 1 && subscriptionType <= 3)) return false;
            dispatch(subscribe_chooseSubscription(subscriptionType));
        }
        else {
            setShakeToggler((v) => !v);
        }
        return false;
    }, [termsAgreed]);

    const subscriptionOptions = subscribe.get('options').toJS();
    const items = subscriptionOptions.map((subscriptionOption) =>
        <Button key={subscriptionOption.id} className="cuddle" fluid size="large" onClick={(ev) => onChooseSubscription(ev, subscriptionOption.id)}>
            {subscriptionOption.duration==12?'1 Jahres':(subscriptionOption.duration==1?'1 Monat':subscriptionOption.duration+' Monate')} Abo
            <div className="subscription-info">
                Du zahlst<br />
                {(Math.round(subscriptionOption.price * 100.0) / 100.0).toFixed(2).replace('.', ',').replace(',00', '')} € / Monat
            </div>
        </Button>
    );

    const allNavItems = useSelector(getNavigationItems);
    const navItemAGBs = allNavItems?.find(m => m.get('title') == 'AGB');

    return (<>
        <Header as='h2' className="cuddle" textAlign="center">
            Bitte wähle dein Wunschabo
        </Header>

        <Form className="subscription-buttons">
            {items}

            <div className="subscription-seperator"></div>
        </Form>

        <Message className="cuddle">
            Die Abos können bis 14 Tage vorher gekündigt werden. Du bekommst dazu eine Nachricht, wenn deine Kündigungsfrist abläuft. Die Abos verlängern sich nicht automatisch.
        </Message>

        <Transition animation="shake" visible={shakeToggler}>
            <Message className="cuddle">
                <Checkbox data-cy="terms" onChange={(ev, data) => setTermsAgreed(data.checked)} label={<label>Ich habe die <a href={navItemAGBs?.get('path')} target="_blank">AGBs</a> gelesen und stimme zu.</label>} />
            </Message>
        </Transition>
    </>);
};

const PaymentChoiceForm = ({subscribe}) => {
    const dispatch = useDispatch();
    const onPayment_PayPalClick = useCallback((ev, d) => {
        ev.preventDefault();
        ev.stopPropagation();
        window.setTimeout(() => {
            document.querySelector('#payment_paypal').checked = true; 
        }, 1);
        return false;
    });
    const onPayment_PaydirektClick = useCallback((ev, d) => {
        ev.preventDefault();
        ev.stopPropagation();
        
        window.setTimeout(() => {
            document.querySelector('#payment_paydirekt').checked = true; 
        }, 1);
        
        return false;
    });

    const onNext = useCallback((ev, d) => {
        ev.preventDefault();

        if(document.querySelector('#payment_paypal').checked) {
            dispatch(subscribe_choosePaymentMethod(1, {}));
        }
        else if(document.querySelector('#payment_paydirekt').checked) {
            //dispatch(subscribe_choosePaymentMethod(2));
            dispatch(subscribe_fillAddress());
        }

        return false;
    });

    return (<>
        <Header as='h2' className="cuddle" textAlign="center">
            Vielen Dank für deine Auswahl.<br />Bitte gib nun deine gewünschte Zahlart ein.
        </Header>

        <Button fluid size="large" className="message" onClick={onPayment_PayPalClick} style={{ marginBottom: 10 }}>
            <input type="radio" name="payment" id="payment_paypal" value="1" style={{ height: 20, marginRight: 10 }} checked />
            <img src="https://www.paypalobjects.com/webstatic/de_DE/i/de-pp-logo-200px.png" width="100" border="0" alt="PayPal" />
        </Button>

        <Button fluid size="large" className="message" onClick={onPayment_PaydirektClick} style={{ marginTop: 5 }}>
            <input type="radio" name="payment" id="payment_paydirekt" value="3" style={{ height: 40, marginRight: 10 }} />
            <img src="https://www.paydirekt.de/presse/medien/logos/ohne-schutzzone/4c_ohne_schutzzone/paydirekt_logo_4c_k.png" width="100" border="0" alt="Paydirekt" />
        </Button>
        <div style={{ clear: 'both', marginBottom: 15 }}></div>
        
        <Button className="cuddle" fluid size="large" onClick={onNext}>
            weiter
        </Button>
    </>);
};

const Summary = ({subscribe}) => {
    const dispatch = useDispatch();
    const onNext = useCallback((ev, d) => {
        ev.preventDefault();
        const subscription = subscribe.get('subscription');
        const paymentMethod = subscribe.get('payment_method');

        if(paymentMethod == 1) {
            dispatch(subscribe_payPalInitialize(subscription));
        }
        else if(paymentMethod == 2) {
            const address = subscribe.get('address');
            dispatch(subscribe_paydirektInitialize(subscription, address));
        }
        return false;
    });

    const address = subscribe.get('address');
    const paymentMethod = subscribe.get('payment_method');
    const subscriptionId = subscribe.get('subscription');
    const subscriptionOptions = subscribe.get('options').toJS();
    const subscription = subscriptionOptions.find(s => s.id == subscriptionId);
    const priceFinal = subscription.price * subscription.duration;
    const priceFormatted = (Math.round(subscription.price * 100.0) / 100.0).toFixed(2).replace('.', ',') + ' €';
    const priceFinalFormatted = (Math.round(priceFinal * 100.0) / 100.0).toFixed(2).replace('.', ',') + ' €';

    return (
        <Message>
            <Message.Header>Übersicht</Message.Header>
            <Table celled>
                <Table.Body>
                <Table.Row>
                        <Table.Cell>Zahlungsart:</Table.Cell>
                        <Table.Cell textAlign="right">{['PayPal', 'paydirekt'][paymentMethod-1]}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Laufzeit:</Table.Cell>
                        <Table.Cell textAlign="right">{subscription.duration==12?'1 Jahr':(subscription.duration==1?'1 Monat':subscription.duration+' Monate')}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Kosten (pro Monat):</Table.Cell>
                        <Table.Cell textAlign="right">{priceFormatted}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Gesamt:</Table.Cell>
                        <Table.Cell textAlign="right">{priceFinalFormatted}</Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>

            {paymentMethod == 2 && <Table celled>
                <Table.Body>
                <Table.Row>
                        <Table.Cell>Vorname:</Table.Cell>
                        <Table.Cell textAlign="right">{address.firstname}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Nachname:</Table.Cell>
                        <Table.Cell textAlign="right">{address.lastname}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>PLZ:</Table.Cell>
                        <Table.Cell textAlign="right">{address.zip}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Land:</Table.Cell>
                        <Table.Cell textAlign="right">{Countries.map(c => c.short == address.countryCode ? c.long : '')}</Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>}

            <Message className="cuddle">
                Hinweis: Das Abo verlängert sich nicht automatisch.
            </Message>

            <Button className="cuddle" fluid size="large" onClick={onNext}>
                jetzt bezahlen
            </Button>
            <div style={{ marginBottom: 15 }}></div>
            
            <Link to="/">
                Zurück zur Startseite
            </Link>
        </Message>
    );
};

const FillAddressForm = ({subscribe}) => {
    const dispatch = useDispatch();
    const onSubmit = useCallback((ev, d) => {
        ev.preventDefault();
        const t = ev.target;
        const firstname = t.firstname.value;
        const lastname = t.lastname.value;
        const zip = t.zip.value;
        const countryCode = t.countryCode.value;
        dispatch(subscribe_choosePaymentMethod(2, {
            firstname: firstname,
            lastname: lastname,
            zip: zip,
            countryCode: countryCode
        }));
        return false;
    });
    const countryCodeOnChange = (e, {value}) => {
        document.getElementById('formAddress').countryCode.value = value;
    };
    const errors = subscribe.get('errors');

    return (
        <Message>
            <Message.Header>Zahlungsinformationen</Message.Header>
            <div style={{ marginBottom: 15 }}></div>

            <Form id="formAddress" size="large" onSubmit={onSubmit} error={isDefined(errors)}>
                <Form.Input fluid required
                    placeholder="Vorname" name="firstname" type="text"
                    autoComplete="firstname" autoFocus
                    error={fieldErr(errors, 'firstname')} />
                <Form.Input fluid required
                    placeholder="Nachname" name="lastname" type="text"
                    autoComplete="lastname" error={fieldErr(errors, 'lastname')} />
                <Form.Input fluid required
                    placeholder="PLZ" name="zip" type="number"
                    autoComplete="zip" error={fieldErr(errors, 'zip')} />
                <Form.Dropdown fluid required
                    placeholder="Land"
                    selection
                    defaultValue="DE"
                    options={Countries.map(c => ({ key: c.short, value: c.short, flag: c.short.toLowerCase(), text: c.long }))}
                    onChange={countryCodeOnChange}
                    autoComplete="countryCode" error={fieldErr(errors, 'countryCode')} />
                <Form.Input type="hidden" name="countryCode" value="DE" />
                {isPresent(errors)?<Message error>{errors}</Message>:null}
                <Button className="cuddle" fluid size="large">
                    weiter
                </Button>
            </Form>
            <div style={{ marginBottom: 15 }}></div>

            <Link to="/">
                Zurück zur Startseite
            </Link>
        </Message>
    );
};

const Done = ({subscribe}) => {
    const message = subscribe.get('message');
    return (
        <Message>
            <Message.Header>Vielen Dank!</Message.Header>
            <div style={{ marginBottom: 15 }}></div>
            <p>Viel Spaß und Erfolg auf unserer Kuschelseite.</p>
            <p>Bei Fragen wende dich an <a href="mailto:admin@kuscheldate.net">admin@kuscheldate.net</a></p>
            <div style={{ marginBottom: 15 }}></div>
            <Link to="/">
                Zurück zur Startseite
            </Link>
        </Message>
    );
};

const Cancel = ({subscribe}) => {
    const message = subscribe.get('message');
    return (
        <Message>
            <Message.Header>Abbruch</Message.Header>
            <p>Der Vorgang wurde abgebrochen.</p>
            <Link to="/">
                Zurück zur Startseite
            </Link>
        </Message>
    );
};

const Error = ({subscribe}) => {
    const message = subscribe.get('message');
    return (
        <Message>
            <Message.Header>Fehler</Message.Header>
            <p>
                Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.
            </p>
            <Link to="/">
                Zurück zur Startseite
            </Link>
        </Message>
    );
};

const Blocked = ({subscribe}) => {
    const message = subscribe.get('message');
    return (
        <Message>
            <Message.Header>Abo buchen</Message.Header>
            <p>
                Du hast bereits ein aktives Abo.
            </p>
            <Link to="/">
                Zurück zur Startseite
            </Link>
        </Message>
    );
};

const SubscribePage = ({}) => {
    const dispatch = useDispatch();
    const subscribe = useSelector(getSubscribe);
    useEffect(() => {
        dispatch(subscribe_initialize());
    }, []);

    const { status } = useParams();
    const isLoading = subscribe.get('loading') || false;
    const isAddressRequired = subscribe.get('addressRequired') || false;
    const isCancel = status == 'cancel' || subscribe.get('cancel') || false;
    const isError = status == 'error' || subscribe.get('error') || false;
    const isDone = status == 'done' || subscribe.get('done') || false;
    const isBlocked = status != 'done' && (subscribe.get('blocked') || false);

    const subscription = subscribe.get('subscription');
    const payment_method = subscribe.get('payment_method');
    const isStepChooseSubscription = !subscription;
    const isStepChoosePayment = !isAddressRequired && subscription && !payment_method;
    const isStepFillAddress = !payment_method && isAddressRequired;
    const isStepSummary = subscription && payment_method;

    let form;
    if(isBlocked)
        form = <Blocked subscribe={subscribe} />
    else if(isDone)
        form = <Done subscribe={subscribe} />
    else if(isCancel)
        form = <Cancel subscribe={subscribe} />
    else if(isError)
        form = <Error subscribe={subscribe} />
    else if(isStepChooseSubscription)
        form = <SubscriptionChoiceForm subscribe={subscribe} />
    else if(isStepChoosePayment)
        form = <PaymentChoiceForm subscribe={subscribe} />
    else if(isStepFillAddress)
        form = <FillAddressForm subscribe={subscribe} />
    else if(isStepSummary)
        form = <Summary subscribe={subscribe} />

    return (
        <Segment className="transparent subscribe-page">
            <Grid textAlign='center'>
                <Grid.Column style={{ maxWidth: 700}}>
                    <Segment stacked>
                        {form}
                        <Dimmer active={isLoading}>
                            <Loader />
                        </Dimmer>
                    </Segment>
                </Grid.Column>
            </Grid>
        </Segment>
    );
};

SubscribePage.defaultProps = {};
SubscribePage.propTypes = {};

export default SubscribePage;
