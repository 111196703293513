import Immutable from 'immutable';
import * as defaultState from "./defaultState";
import * as constants from "../constants";
import {
    isPresent, isDefined, makeApiListUnique, genId, findBlockPath, formatDate,
} from "../helpers";

const getErrorDetail = (err) => {
    try {
        return err.get('detail') || err.get('message') || err.get('errors');
    } catch (e) {
        return (err && err.message) || err;
    }
};

export function auth(state=defaultState.AUTH, action) {
    const pl = action.payload;
    switch (action.type) {
    case constants.FETCH_INITIAL_DATA_DONE:
        if (action.payload.data.has("session")) {
            return state.merge(action.payload.data.get('session'));
        } else {
            return state;
        }
    case constants.AUTHENTICATE_DONE:
        return state.merge(Immutable.fromJS(pl.data));
    case constants.DELETE_OWN_ACCOUNT_DONE:
    case constants.DEAUTHENTICATE_DONE:
        delete window.localStorage.session_token;
        return defaultState.AUTH;
    case constants.AUTHENTICATE_ERROR:
        return state.set("errorMessage", pl.errorMessage);
    case constants.DELETE_PROFILE_IMAGE_DONE:
        return state.set('profile_image_url', 'unset_avatar.svg');
    case constants.SAVE_PROFILE_DONE:
        return state.set('nickname', pl.data.get('nickname')).
            set('location', pl.data.get('location')).
            set('locatable', pl.data.get('locatable')).
            set('profile_image_url', pl.data.get('profile_image_url'));
    case constants.SAVE_PROFILE_EDIT_DONE:
        return state.set('nickname', pl.result.get('nickname')).
            set('location', pl.result.get('location')).
            set('locatable', pl.result.get('locatable')).
            set('profile_image_url', pl.result.get('profile_image_url'));
    case constants.SET_AUTH_TOKEN:
        if(pl.update_store){
            if(pl.token) {
                window.localStorage.session_token = pl.token;
            } else {
                delete window.localStorage.session_token;
            }
        }
        return state.set('token', pl.token);
    default:
        return state;
    }
}

export function registration(state=defaultState.REGISTRATION, action) {
    const pl = action.payload;
    switch (action.type) {
    case constants.CREATE_ACCOUNT:
    case constants.SEND_CONFIRMATION:
    case constants.ACCEPT_INVITATION:
        return defaultState.REGISTRATION.set('loading', true);
    case constants.SEND_RECONFIRMATION:
        return state.set('loading', true);
    case constants.CREATE_ACCOUNT_DONE:
    case constants.SEND_CONFIRMATION_DONE:
    case constants.SEND_RECONFIRMATION_DONE:
    case constants.ACCEPT_INVITATION_DONE:
        return defaultState.REGISTRATION.set('success', pl.success)
            .set('message', pl.message);
    case constants.CREATE_ACCOUNT_ERROR:
    case constants.SEND_CONFIRMATION_ERROR:
    case constants.SEND_RECONFIRMATION_ERROR:
    case constants.ACCEPT_INVITATION_ERROR:
        const errors = getErrorDetail(pl.error);
        return state.set('loading', false).set('errors', errors)
              .set('email', pl.error.get('email'));
    case constants.CLEAR_REGISTRATION:
        return defaultState.REGISTRATION;
    default:
        return state;
    }
}

export function subscribe(state=defaultState.SUBSCRIBE, action) {
    const pl = action.payload;
    switch (action.type) {
        case constants.SUBSCRIBE_INITIALIZED:
            return state.set('blocked', !pl.success).set('options', pl.options).set('loading', false);
        case constants.SUBSCRIBE_FILL_ADDRESS:
            return state.set('addressRequired', true);
        case constants.SUBSCRIBE_PAYDIREKT_INITIALIZE:
            return state.set('payment_paydirekt', action.payload.payment_paydirekt).set('loading', true);
        case constants.SUBSCRIBE_PAYPAL_INITIALIZE:
            return state.set('payment_paypal', action.payload.payment_paypal).set('loading', true);
        case constants.SUBSCRIBE_CHOOSE_SUBSCRIPTION:
            return state.set('subscription', action.payload.subscription);
        case constants.SUBSCRIBE_CHOOSE_PAYMENTMETHOD:
            return state.set('payment_method', action.payload.payment_method).set('address', action.payload.address);
        case constants.SUBSCRIBE_PAYDIREKT_INITIALIZED:
            return state.set('redirect_url', action.payload.redirect_url);
        case constants.SUBSCRIBE_PAYPAL_INITIALIZED:
            return state.set('redirect_url', action.payload.redirect_url);
        case constants.SUBSCRIBE_ERROR:
            return defaultState.SUBSCRIBE.set('error', true).set('message', action.payload.message);
        case constants.CLEAR_SUBSCRIBE:
            return defaultState;
        case constants.SUBSCRIBE_INITIALIZE:
            return defaultState.SUBSCRIBE.set('loading', true);
        default:
            return state;
    }
}

export function profiles(state=defaultState.PROFILES, action) {
    const {payload} = action;
    
    switch (action.type) {
    case constants.FETCH_PROFILE_DONE:
        return state.set(payload.slug, Immutable.Map(payload.data).merge({
            error: null,
            loading: false,
            saved: false,
        }));
    case constants.SAVE_PROFILE:
        return state.setIn([payload.slug, "nickname"], payload.data.get('nickname'))
                    .setIn([payload.slug, "geolocation"], payload.data.get('geolocation'))
                    .setIn([payload.slug, "description"], payload.data.get('description'))
                    .setIn([payload.slug, "cuddling_type"], payload.data.get('cuddling_type'))
                    .setIn([payload.slug, "cuddling_touch"], payload.data.get('cuddling_touch'))
                    .setIn([payload.slug, "cuddling_positions"], payload.data.get('cuddling_positions') ?? Immutable.Map())
                    .setIn([payload.slug, "cuddling_talk"], payload.data.get('cuddling_talk'))
                    .setIn([payload.slug, "cuddling_space"], payload.data.get('cuddling_space'))
                    .setIn([payload.slug, "profile_image"], payload.data.get('profile_image'))
                    .setIn([payload.slug, "profile_image_url"], payload.data.get('profile_image_url'));
    case constants.SAVE_PROFILE_DONE:
        return state.set(payload.slug, Immutable.Map(payload.data).merge({
            error: null,
            loading: false,
            saved: true,
        }));
    case constants.FETCH_PROFILE_ERROR:
    case constants.SAVE_PROFILE_ERROR:
        const errors = getErrorDetail(payload.error);
        return state.setIn([payload.slug, "error"], errors)
                    .setIn([payload.slug, "loading"], false)
                    .setIn([payload.slug, "saved"], false);
    case constants.FETCH_PROFILE_LOADING:
    case constants.SAVE_PROFILE_ONGOING:
        return state.setIn([payload.slug, "error"], null)
                    .setIn([payload.slug, "loading"], true)
                    .setIn([payload.slug, "saved"], false);
    default:
        return state;
    }
}

export function profile_comments(state=defaultState.PROFILE_COMMENTS, action) {
    const {payload} = action;
    const {slug, profileSlug} = payload || {};
    let commentIdx;
    switch(action.type) {
    case constants.FETCH_NEXT_PROFILE_COMMENTS:
        return ((!state.has(slug)) ? state.set(slug, Immutable.fromJS({
            hasMore: true,
            nextUrl: null,
            result: [],
        })) : state).setIn([slug, 'loading'], true).setIn([slug, 'error'], null);
    case constants.FETCH_NEXT_PROFILE_COMMENTS_DONE:
        let changed_comments = state.getIn([slug, 'result']) || Immutable.List();
        if (isDefined(payload.new_comments)) {
            changed_comments = payload.new_comments.concat(changed_comments);
        }
        if (isDefined(payload.comments)) {
            changed_comments = changed_comments.concat(payload.comments);
        }
        changed_comments = makeApiListUnique(changed_comments);
        return state.set(slug, Immutable.fromJS({
            hasMore: isPresent(payload.nextUrl),
            nextUrl: payload.nextUrl,
            loading: false,
            error: null,
            result: changed_comments,
        }));
    case constants.FETCH_NEXT_PROFILE_COMMENTS_ERROR:
        return state.setIn([slug, "error"], getErrorDetail(payload.error))
                    .setIn([slug, "loading"], false);
    case constants.SEND_PROFILE_COMMENT:
        return ((!state.has(slug)) ? state.set(slug, Immutable.fromJS({
            hasMore: true,
            nextUrl: null,
            result: [],
        })): state).setIn([slug, "sendError"], null).setIn([slug, "loading"], true);
    case constants.SEND_PROFILE_COMMENT_DONE:
        return state.setIn([slug, 'loading'], false);
    case constants.SEND_PROFILE_COMMENT_ERROR:
        return state.setIn([slug, "sendError"], getErrorDetail(payload.error))
                    .setIn([slug, "loading"], false);
    case constants.CLEAR_PROFILE_COMMENTS:
        return state.delete(slug);
    case constants.DELETE_PROFILE_COMMENT:
        commentIdx = state.getIn([profileSlug, "result"]).findIndex(
            comment => comment.get('slug') === slug
        );
        if(commentIdx === -1) {
            console.error("Reducer failed finding profile comment", slug);
            return state;
        }
        return state.setIn([profileSlug, "result", commentIdx, "loading"], true);
    case constants.DELETE_PROFILE_COMMENT_DONE:
        commentIdx = state.getIn([profileSlug, "result"]).findIndex(
            comment => comment.get('slug') === slug
        );
        if(commentIdx === -1) {
            console.error("Reducer failed finding profile comment", slug);
            return state;
        }
        if (payload.comment.get('destroyed') === true) {
            return state.deleteIn([profileSlug, "result", commentIdx]);
        } else {
            return state.setIn([profileSlug, "result", commentIdx], payload.comment);
        }
    case constants.DELETE_PROFILE_COMMENT_ERROR:
        commentIdx = state.getIn([profileSlug, "result"]).findIndex(
            comment => comment.get('slug') === slug
        );
        if(commentIdx === -1) {
            console.error("Reducer failed finding profile comment", slug);
            return state;
        }
        return state.setIn([profileSlug, "result", commentIdx, "loading"], false)
                    .setIn([profileSlug, "result", commentIdx, "error"],
                           getErrorDetail(payload.error));
    default:
        return state;
    };
};

export function profile_images(state=defaultState.PROFILE_IMAGES, action) {
    const payload = action.payload || {};
    switch (action.type) {
    case constants.FETCH_PROFILE_IMAGES:
        return state.set('loading', true).set('error', null);
    case constants.FETCH_PROFILE_IMAGES_DONE:
        return state.set('loading', false).set('error', null)
                    .set('images', payload.images)
                    .set('currentImageId', payload.currentImageId)
                    .set('uploadQuota', payload.uploadQuota);
    case constants.FETCH_PROFILE_IMAGES_ERROR:
        return state.set('loading', false).set('error', getErrorDetail(payload.error));
    case constants.DELETE_PROFILE_IMAGE_DONE:
        const currentSid = state.get("currentImageId");
        return state.set('images',
            state.get('images').filter(i => i.get('signed_id') !== payload.sid)
        ).set("currentImageId", (currentSid === payload.sid) ? null : currentSid)
         .set("uploadQuota", payload.uploadQuota);
    case constants.DELETE_PROFILE_IMAGE_ERROR:
        return state.set('uploadQuota', payload.uploadQuota);
    default:
        return state;
    }
}

export function profile_edit(state=defaultState.PROFILE_EDIT, action) {
    const payload = action.payload || {};
    switch (action.type) {
    case constants.FETCH_PROFILE_EDIT:
    case constants.SAVE_PROFILE_EDIT:
        return state.set('loading', true).set('error', null);
    case constants.FETCH_PROFILE_EDIT_DONE:
    case constants.SAVE_PROFILE_EDIT_DONE:
        return state.set('loading', false).set('error', null)
                    .set('result', payload.result);
    case constants.FETCH_PROFILE_EDIT_ERROR:
    case constants.SAVE_PROFILE_EDIT_ERROR:
        return state.set('loading', false).set('error', getErrorDetail(payload.error));
    default:
        return state;
    }
}

export function groups(state=defaultState.GROUPS, action) {
    const {payload} = action;
    switch (action.type) {
    case constants.FETCH_GROUP:
        return state.setIn(['all', payload.slug], Immutable.fromJS({
            error: null,
            loading: true,
            saved: false,
        })).set('new', defaultState.GROUPS.get('new'));
    case constants.FETCH_GROUP_DONE:
        //FIXME: rename data to group
        return state.setIn(['all', payload.slug], Immutable.Map(payload.data).merge({
            error: null,
            loading: false,
            saved: false,
        }));
    case constants.FETCH_GROUP_ERROR:
        return state.setIn(['all', payload.slug], Immutable.Map({
            error: getErrorDetail(payload.error),
            loading: false,
            saved: false,
        }));
    case constants.SAVE_GROUP:
        return state.set('new', Immutable.fromJS({
            error: null,
            saved: false,
        }));
    case constants.SAVE_GROUP_DONE:
        return state.set('new', Immutable.fromJS({
            error: null,
            saved: true,
            slug: payload.group.get('slug'),
        })).setIn(['all', payload.group.get('slug')], Immutable.Map(payload.group).merge({
            error: null,
            loading: false,
            saved: true,
        }));
    case constants.SAVE_GROUP_ERROR:
        return state.set('new', Immutable.fromJS({
            error: getErrorDetail(payload.error),
            saved: false,
        }));
    case constants.CLEAR_NEW_GROUP:
        return state.set('new', Immutable.Map());
    case constants.DELETE_GROUP:
        return state.setIn(['all', payload.slug, "loading"], true);
    case constants.DELETE_GROUP_DONE:
        return state.deleteIn(['all', payload.slug]);
    case constants.DELETE_GROUP_ERROR:
        return state.setIn(['all', payload.slug, "loading"], false)
                    .setIn(['all', payload.slug, "error"],
                        getErrorDetail(payload.error));
    default:
        return state;
    }
}

export function group_comments(state=defaultState.GROUP_COMMENTS, action) {
    const {payload} = action;
    const {groupSlug, slug} = payload || {};
    let commentIdx;
    switch(action.type) {
    case constants.FETCH_NEXT_GROUP_COMMENTS:
        return ((!state.has(groupSlug)) ? state.set(groupSlug, Immutable.fromJS({
            hasMore: true,
            nextUrl: null,
            result: [],
        })) : state).setIn([groupSlug, 'loading'], true).setIn([groupSlug, 'error'], null);
    case constants.FETCH_NEXT_GROUP_COMMENTS_DONE:
        let changed_comments = state.getIn([groupSlug, 'result']);
        if (isDefined(payload.new_comments)) {
            changed_comments = payload.new_comments.concat(changed_comments);
        }
        if (isDefined(payload.comments)) {
            changed_comments = changed_comments.concat(payload.comments);
        }
        changed_comments = makeApiListUnique(changed_comments);
        return state.set(groupSlug, Immutable.fromJS({
            hasMore: isPresent(payload.nextUrl),
            nextUrl: payload.nextUrl,
            loading: false,
            error: null,
            result: changed_comments,
        }));
    case constants.FETCH_NEXT_GROUP_COMMENTS_ERROR:
        return state.setIn([groupSlug, "error"], getErrorDetail(payload.error))
                    .setIn([groupSlug, "loading"], false);
    case constants.SEND_GROUP_COMMENT:
        return ((!state.has(groupSlug)) ? state.set(groupSlug, Immutable.fromJS({
            hasMore: true,
            nextUrl: null,
            result: [],
        })): state).setIn([groupSlug, "sendError"], null)
                   .setIn([groupSlug, "loading"], true);
    case constants.SEND_GROUP_COMMENT_DONE:
        return state.setIn([groupSlug, 'loading'], false);
    case constants.SEND_GROUP_COMMENT_ERROR:
        return state.setIn([groupSlug, "sendError"], getErrorDetail(payload.error))
                    .setIn([groupSlug, "loading"], false);
    case constants.CLEAR_GROUP_COMMENTS:
        return state.delete(groupSlug);
    case constants.DELETE_GROUP_COMMENT:
        commentIdx = state.getIn([groupSlug, "result"]).findIndex(
            comment => comment.get('slug') === slug
        );
        if(commentIdx === -1) {
            console.error("Reducer failed finding group comment", slug);
            return state;
        }
        return state.setIn([groupSlug, "result", commentIdx, "loading"], true);
    case constants.DELETE_GROUP_COMMENT_DONE:
        commentIdx = state.getIn([groupSlug, "result"]).findIndex(
            comment => comment.get('slug') === slug
        );
        if(commentIdx === -1) {
            console.error("Reducer failed finding group comment", slug);
            return state;
        }
        if (payload.comment.get('destroyed') === true) {
            return state.deleteIn([groupSlug, "result", commentIdx]);
        } else {
            return state.setIn([groupSlug, "result", commentIdx], payload.comment);
        }
    case constants.DELETE_GROUP_COMMENT_ERROR:
        commentIdx = state.getIn([groupSlug, "result"]).findIndex(
            comment => comment.get('slug') === slug
        );
        if(commentIdx === -1) {
            console.error("Reducer failed finding group comment", slug);
            return state;
        }
        return state.setIn([groupSlug, "result", commentIdx, "loading"], false)
                    .setIn([groupSlug, "result", commentIdx, "error"],
                        getErrorDetail(payload.error));
    case constants.DELETE_GROUP_DONE:
        return state.delete(slug);
    default:
        return state;
    };
};

export function app(state=defaultState.APP, action) {
    const {payload} = action;
    let idx, items;
    switch (action.type) {
    case constants.SHOW_TOAST:
        return state.set('toastMessages', state.get('toastMessages').push(
            Immutable.fromJS(payload)
        ));
    case constants.CLEAR_ALL_TOASTS:
        return state.set('toastMessages', Immutable.List());
    case constants.CLEAR_TOAST:
        items = state.get('toastMessages');
        idx = items.findIndex(val => val.get('key') === payload.key);
        if (idx >= 0 ) {
            return state.set('toastMessages', items.remove(idx))
        } else {
            return state;
        }
    case constants.SHOW_MODAL:
        return state.set('modalMessages', state.get('modalMessages').push(
            Immutable.fromJS(payload)
        ));
    case constants.CLEAR_ALL_MODALS:
        return state.set('modalMessages', Immutable.List());
    case constants.CLEAR_HEAD_MODAL:
        return state.set('modalMessages',
            state.get('modalMessages').shift());
    case constants.HIDE_LOADING:
        return state.set('loading', false);
    case constants.FETCH_INITIAL_DATA_DONE:
        return state.set('loaded', true);
    case constants.FETCH_INITIAL_DATA_ERROR:
        return state.set('loadingError', getErrorDetail(payload.error));
    default:
        return state;
    }
}

export function pages(state=defaultState.PAGES, action) {
    const {payload} = action;
    switch (action.type) {
    case constants.FETCH_INITIAL_DATA_DONE:
        return state.merge(action.payload.data.get('pages'));
    case constants.FETCH_PAGES_DONE:
        return state.merge(action.payload);
    case constants.DELETE_PAGE_DONE:
        return state.delete(payload.slug);
    case constants.SAVE_PAGE_EDIT_DONE:
        return state.set(payload.page.get('slug'), payload.page);
    default:
        return state;
    }
}

export function menu_items(state=defaultState.MENU_ITEMS, action) {
    const {payload} = action;
    switch (action.type) {
    case constants.FETCH_INITIAL_DATA_DONE:
        return state.merge(action.payload.data.get('menu_items'));
    case constants.SAVE_NAVIGATION_EDIT_DONE:
        const deleteKeys = action.payload.entries.filter(v => v === null).keySeq();
        return state.merge(action.payload.entries).deleteAll(deleteKeys);
    default:
        return state;
    }
}

export function online_users(state=defaultState.ONLINE_USERS, action) {
    switch (action.type) {
    case constants.FETCH_ONLINE_USERS_DONE:
        return action.payload;
    default:
        return state;
    }
}

export function chat(state=defaultState.CHAT, action) {
    const payload = action.payload || {};
    const {conversationId} = payload;
    let {messages} = payload;
    let message;
    let transitMessageIndex;
    switch (action.type) {
    case constants.FETCH_INITIAL_DATA_DONE:
    case constants.AUTHENTICATE_DONE:
        return defaultState.CHAT;
    case constants.FETCH_CONVERSATIONS_DONE:
        return state.set('conversations', payload.conversations);
    case constants.SYNC_CONVERSATION_OPEN:
        const conv = state.get('conversations')
                          .find(c => c.get('slug') === payload.conversation.get('slug'));
        if (conv === undefined) {
            return state.set('conversations',
                state.get('conversations').push(payload.conversation));
        } else {
            return state;
        }
    case constants.SYNC_MESSAGES:
        messages = messages.sortBy(v => v.get('created_at'));
        const transitMessages = state.getIn(["messages", conversationId])?.
            filter(m => isPresent(m.get("transitKey")));
        if (transitMessages && !transitMessages.isEmpty()) {
            messages = messages.concat(transitMessages.map(
                tm => tm.set("undelivered", true)
            ));
        }
        return state.setIn(['messages', conversationId], messages);
    case constants.SYNC_NEW_MESSAGE:
        message = payload.message;
        messages = state.getIn(['messages', conversationId]);
        if(isPresent(payload.transitKey)) {
            transitMessageIndex = messages.findLastIndex(
                m => m.get("transitKey") === payload.transitKey
            );
            if(transitMessageIndex !== -1) {
                messages = messages.set(transitMessageIndex, message);
            } else {
                messages = messages.push(message);
            }
        } else {
            messages = messages.push(message);
        }
        messages = messages.sortBy(v => v.get('created_at'));
        return state.setIn(['messages', conversationId], messages);
    case constants.SYNC_CHAT_COUNT_NOTIFICATION:
        const {chatCount} = payload;
        const oldCountMap = state.get("unreadCount") || Immutable.Map();
        return state.set("unreadCount", oldCountMap.merge(chatCount));
    case constants.SET_CHAT_ONLINE:
        return state.set('online', payload.value)
    case constants.STORE_TRANSIT_MESSAGE:
        message = Immutable.fromJS({
            transitKey: payload.key,
            slug: payload.key,
            text: payload.text,
            self_seen: true,
            created_at: formatDate(new Date(), null),
        });
        messages = state.getIn(['messages', conversationId]);
        transitMessageIndex = messages.findLastIndex(
            m => m.get("transitKey") === payload.key
        );
        if(transitMessageIndex !== -1) {
            messages = messages.set(transitMessageIndex, message);
        } else {
            messages = messages.push(message);
        }
        return state.setIn(['messages', conversationId], messages);
    case constants.REMOVE_CONVERSATION:
        return state.set('conversations', state.get('conversations').filter((item) => item.get('slug') !== payload.slug));
    default:
        return state;
    }
}

export function notifications(state=defaultState.NOTIFICATIONS, action) {
    const payload = action.payload || {};
    switch (action.type) {
    case constants.SYNC_MOD_TASKS_COUNT:
        return state.set('modTasks', payload.count);
    default:
        return state;
    }
}

export function search(state=defaultState.SEARCH, action) {
    const payload = action.payload || {};
    switch (action.type) {
    case constants.SEARCH_USERS:
        return state.setIn(['users', 'loading'], true).
            setIn(['users', 'loaded'], false).
            setIn(['users', 'error'], null);
    case constants.SEARCH_USERS_DONE:
        return state.setIn(['users', 'result'], payload.users).
            setIn(['users', 'loading'], false).
            setIn(['users', 'loaded'], true);
    case constants.SEARCH_USERS_CLEAR:
        return state.set('users', defaultState.SEARCH.get('users'));
    case constants.SEARCH_USERS_ERROR:
        return state.setIn(['users', 'error'], getErrorDetail(payload.error))
                    .setIn(['users', 'loading'], false)
                    .setIn(['users', 'loaded'], true);
    case constants.SEARCH_GROUPS:
        return state.setIn(['groups', 'loading'], true).
            setIn(['groups', 'loaded'], false).
            setIn(['groups', 'error'], null);
    case constants.SEARCH_GROUPS_DONE:
        return state.setIn(['groups', 'result'], payload.groups).
            setIn(['groups', 'loading'], false).
            setIn(['groups', 'loaded'], true);
    case constants.SEARCH_GROUPS_CLEAR:
        return state.set('groups', defaultState.SEARCH.get('groups'));
    case constants.SEARCH_GROUPS_ERROR:
        return state.setIn(['groups', 'error'], getErrorDetail(payload.error))
                    .setIn(['groups', 'loading'], false)
                    .setIn(['groups', 'loaded'], true);
    case constants.SEARCH_GEOLOCATIONS:
        return state.setIn(['geolocations', 'loading'], true).
            setIn(['geolocations', 'loaded'], false).
            setIn(['geolocations', 'error'], null);
    case constants.SEARCH_GEOLOCATIONS_DONE:
        return state.setIn(['geolocations', 'result'], payload.locations).
            setIn(['geolocations', 'loading'], false).
            setIn(['geolocations', 'loaded'], true);
    case constants.SEARCH_GEOLOCATIONS_CLEAR:
        return state.set('geolocations', defaultState.SEARCH.get('geolocations'));
    case constants.SEARCH_GEOLOCATIONS_ERROR:
        return state.setIn(['geolocations', 'error'], getErrorDetail(payload.error))
                    .setIn(['geolocations', 'loading'], false)
                    .setIn(['geolocations', 'loaded'], true);
    default:
        return state;
    }
}

export function redirect(state=defaultState.REDIRECT, action) {
    const payload = action.payload || {};
    switch (action.type) {
    case constants.REDIRECT_TO:
        return action.payload.location;
    case constants.SYNC_CONVERSATION_OPEN:
        if (isPresent(payload.conversationId)){
            return `/chat/${payload.conversationId}`;
        }
        break;
    case constants.SAVE_PAGE_DONE:
        return "/admin/pages/";
    case constants.CREATE_NEW_PAGE:
        return "/admin/pages/_new_page";
    case constants.OPEN_PAGE_EDIT_DONE:
        return `/admin/pages/${payload.slug}`;
    case constants.EDIT_QUOTATION:
        return `/admin/quotations/${payload.slug}`;
    case constants.DELETE_GROUP_DONE:
        return `/groups/`;
    case constants.DELETE_OWN_ACCOUNT_DONE:
        return '/byebye';
    default:
        return state;
    }
}

const NEW_NAVIGATION_ITEM = Immutable.fromJS({
    slug: '_new',
    active: true,
    internal: false,
    location: 'top',
    order: 0,
    page: null,
    path: "",
    title: "Neuer Eintrag",
    visibility: "admin",
    _new: true,
});
const NEW_PAGE_BLOCK = Immutable.fromJS({
    slug: '_new',
    blocks: [],
    body: null,
    class_names: "",
    order: 0,
    _new: true,
});

export function admindata(state=defaultState.ADMINDATA, action) {
    let entries, index, blockPath, block, errors;
    switch (action.type) {
    case constants.SAVE_QUOTATION_DONE:
        return state.set('quotationEdit', Immutable.fromJS({
            error: null,
            loading: false,
        }));
    case constants.SAVE_QUOTATION_ONGOING:
        return state.set('quotationEdit', Immutable.fromJS({
            error: null,
            loading: true,
        }));
    case constants.SAVE_QUOTATION_ERROR:
        return state.set('quotationEdit', Immutable.fromJS({
            error: getErrorDetail(action.payload.error),
            loading: false,
        }));
    case constants.SAVE_QUOTATION_CLEAR:
        return state.set('quotationEdit', defaultState.ADMINDATA.get('quotationEdit'));
    case constants.OPEN_NAVIGATION_EDIT_DONE:
        return state.set('menuEdit', defaultState.ADMINDATA.get('menuEdit').
                     set("done", false).set('entries', action.payload.entries));
    case constants.SAVE_NAVIGATION_EDIT:
        return state.setIn(['menuEdit', 'saving'], true);
    case constants.SAVE_NAVIGATION_EDIT_ERROR:
        return state.setIn(['menuEdit', 'saving'], false).
                     setIn(['menuEdit', 'error'], getErrorDetail(action.payload.error));
    case constants.SAVE_NAVIGATION_EDIT_DONE:
    case constants.CANCEL_NAVIGATION_EDIT:
        return state.set('menuEdit', defaultState.ADMINDATA.get('menuEdit'));
    case constants.MOVE_NAVIGATION_ITEM:
        entries = state.getIn(['menuEdit', 'entries', action.payload.location]);
        const moveEl = entries.get(action.payload.oldPos);
        entries = entries.remove(action.payload.oldPos).insert(action.payload.newPos, moveEl);
        entries = entries.map((m, i) => m.set('order', i));
        return state.setIn(['menuEdit', 'entries', action.payload.location], entries);
    case constants.CHANGE_NAVIGATION_ITEM:
        entries = state.getIn(['menuEdit', 'entries', action.payload.location]);
        entries = entries.set(action.payload.pos, action.payload.item);
        return state.setIn(['menuEdit', 'entries', action.payload.location], entries);
    case constants.NEW_NAVIGATION_ITEM:
        entries = state.getIn(['menuEdit', 'entries', action.payload.location]);
        entries = entries.push(NEW_NAVIGATION_ITEM.set('location', action.payload.location).
                               set('slug', genId()));
        entries = entries.map((m, i) => m.set('order', i));
        return state.setIn(['menuEdit', 'entries', action.payload.location], entries);
    case constants.CREATE_NEW_PAGE:
        return state.set('pageEdit', defaultState.ADMINDATA.get('pageEdit')
            .set("done", false)
            .set('page', Immutable.fromJS({
                slug: "",
                top_image_url: '',
                bottom_image_url: '',
                blocks: [],
                menu_item_id: null,
                authorized_role: 0,
            }))
        );
    case constants.OPEN_PAGE_EDIT_DONE:
        return state.set('pageEdit', defaultState.ADMINDATA.get('pageEdit').
                     set("done", false).set('page', action.payload.page));
    case constants.ADD_NEW_PAGE_BLOCK:
        entries = state.getIn(['pageEdit', 'page', 'blocks']);
        blockPath = findBlockPath(entries, action.payload.blockPath, true);
        entries = entries.getIn(blockPath);
        entries = entries.push(NEW_PAGE_BLOCK.set('slug', genId()));
        entries = entries.map((m, i) => m.set('order', i));
        return state.setIn(['pageEdit', 'page', 'blocks'].concat(blockPath), entries);
    case constants.INSERT_NEW_PAGE_ABOVE_BLOCK:
        entries = state.getIn(['pageEdit', 'page', 'blocks']);
        blockPath = findBlockPath(entries, action.payload.blockPath);
        if(blockPath.length > 0) {
            index = blockPath.pop();
            entries = entries.getIn(blockPath);
            entries = entries.insert(index, NEW_PAGE_BLOCK.set('slug', genId()));
            entries = entries.map((m, i) => m.set('order', i));
            return state.setIn(['pageEdit', 'page', 'blocks'].concat(blockPath), entries);
        }
        return state;
    case constants.INSERT_NEW_PAGE_BELOW_BLOCK:
        entries = state.getIn(['pageEdit', 'page', 'blocks']);
        blockPath = findBlockPath(entries, action.payload.blockPath);
        if(blockPath.length > 0) {
            index = blockPath.pop();
            entries = entries.getIn(blockPath);
            entries = entries.insert(index + 1, NEW_PAGE_BLOCK.set('slug', genId()));
            entries = entries.map((m, i) => m.set('order', i));
            return state.setIn(['pageEdit', 'page', 'blocks'].concat(blockPath), entries);
        }
        return state;
    case constants.DELETE_PAGE_BLOCK:
    case constants.UNDELETE_PAGE_BLOCK:
        entries = state.getIn(['pageEdit', 'page', 'blocks']);
        blockPath = findBlockPath(entries, action.payload.blockPath);
        if(blockPath.length > 0) {
            if(action.type === constants.DELETE_PAGE_BLOCK){
                block = entries.getIn(blockPath).set('_delete', true);
            } else {
                block = entries.getIn(blockPath).delete('_delete');
            }
            return state.setIn(['pageEdit', 'page', 'blocks'].concat(blockPath), block);
        }
        return state;
    case constants.MOVE_PAGE_BLOCK:
        entries = state.getIn(['pageEdit', 'page', 'blocks']);
        blockPath = findBlockPath(entries, action.payload.blockPath, true);
        entries = entries.getIn(blockPath);
        const moveBlock = entries.get(action.payload.oldPos);
        entries = entries.remove(action.payload.oldPos).insert(
            action.payload.newPos, moveBlock);
        entries = entries.map((m, i) => m.set('order', i));
        return state.setIn(['pageEdit', 'page', 'blocks'].concat(blockPath), entries);
    case constants.SET_PAGE_BLOCK_CONTENT:
        entries = state.getIn(['pageEdit', 'page', 'blocks']);
        blockPath = findBlockPath(entries, action.payload.blockPath);
        if (blockPath.length > 0) {
            return state.setIn(['pageEdit', 'page', 'blocks'].concat(blockPath, "body"),
                action.payload.content);
        }
        return state;
    case constants.SET_PAGE_BLOCK_PROPS:
        entries = state.getIn(['pageEdit', 'page', 'blocks']);
        blockPath = findBlockPath(entries, action.payload.blockPath);
        if (blockPath.length > 0) {
            block = entries.getIn(blockPath);
            return state.setIn(['pageEdit', 'page', 'blocks'].concat(blockPath),
                block.merge(action.payload.props));
        }
        return state;
    case constants.SET_PAGE_EDIT_PAGE:
        return state.setIn(['pageEdit', 'page'], action.payload.page);
    case constants.SAVE_PAGE_EDIT_DONE:
    case constants.CANCEL_PAGE_EDIT:
        return state.set('pageEdit', defaultState.ADMINDATA.get('pageEdit'));
    case constants.SAVE_PAGE_EDIT:
        return state.setIn(['pageEdit', 'saving'], true);
    case constants.SAVE_PAGE_EDIT_ERROR:
        return state.setIn(['pageEdit', 'saving'], false).
                     setIn(['pageEdit', 'error'], getErrorDetail(action.payload.error));
    case constants.CLEAR_PAGE_EDIT_ERROR:
        return state.setIn(['pageEdit', 'saving'], false).
                     setIn(['pageEdit', 'error'], null);
    case constants.FETCH_USER_EDIT:
        return state.setIn(['userEdit', 'loading'], true);
    case constants.FETCH_USER_EDIT_DONE:
        return state.setIn(['userEdit', 'users'], action.payload.users)
                    .setIn(['userEdit', 'loading'], false)
                    .setIn(['userEdit', 'error'], null);
    case constants.FETCH_USER_EDIT_ERROR:
        return state.setIn(['userEdit', 'loading'], false).
                     setIn(['userEdit', 'error'], getErrorDetail(action.payload.error));
    case constants.SEND_USER_INVITATION:
        return state.set('userInvite', defaultState.ADMINDATA.get('userInvite')
                                      .set('loading', true));
    case constants.SEND_USER_INVITATION_DONE:
        return state.setIn(['userInvite', 'success'], action.payload.success)
                    .setIn(['userInvite', 'loading'], false)
                    .setIn(['userInvite', 'message'], action.payload.message);
    case constants.SEND_USER_INVITATION_ERROR:
        errors = getErrorDetail(action.payload.error);
        return state.setIn(['userInvite', 'success'], false)
                    .setIn(['userInvite', 'loading'], false)
                    .setIn(['userInvite', 'error'], errors);
    case constants.DELETE_USER:
        return state.set('userDelete', defaultState.ADMINDATA.get('userDelete')
                                      .set('loading', true));
    case constants.DELETE_USER_DONE:
        return state.setIn(['userDelete', 'success'], action.payload.success)
                    .setIn(['userDelete', 'loading'], false)
                    .setIn(['userDelete', 'message'], action.payload.message);
    case constants.DELETE_USER_ERROR:
        errors = getErrorDetail(action.payload.error);
        return state.setIn(['userDelete', 'success'], false)
                    .setIn(['userDelete', 'loading'], false)
                    .setIn(['userDelete', 'error'], errors);
    default:
        return state;
    }
}

export function quotations(state=defaultState.QUOTATIONS, action) {
    switch (action.type) {
    case constants.FETCH_INITIAL_DATA_DONE:
        return Immutable.fromJS({
            error: null,
            loaded: true,
            loading: false,
            result: action.payload.data.get('quotations'),
        });
    case constants.FETCH_QUOTATIONS_LOADING:
        return Immutable.fromJS({
            error: null,
            loaded: false,
            loading: true,
            result: {},
        });
    case constants.FETCH_QUOTATIONS_ERROR:
        return Immutable.fromJS({
            error: getErrorDetail(action.payload.error),
            loaded: false,
            loading: false,
            result: {},
        });
    case constants.FETCH_QUOTATIONS_DONE:
        return Immutable.fromJS({
            error: null,
            loaded: true,
            loading: false,
            result: action.payload.result.toMap().mapEntries(([k, v])=>[v.get('slug'), v]),
        });
    case constants.DELETE_QUOTATION_DONE:
        return state.set('result', state.get('result').delete(action.payload.slug));
    case constants.SAVE_QUOTATION_DONE:
        return state.setIn(['result', action.payload.slug], action.payload.quotation);
    default:
        return state;
    }
}

export function moddata(state=defaultState.MODDATA, action) {
    const pl = action.payload;
    let result;
    switch (action.type) {
    case constants.FETCH_MOD_TASKS_LOADING:
        return state.set('tasks', Immutable.fromJS({
            error: null,
            loading: true,
            result: state.getIn(["tasks", "result"], Immutable.Map()),
        }));
    case constants.FETCH_MOD_TASKS_ERROR:
        return state.set('tasks', state.get('tasks').
                     setIn(['tasks', 'error'], pl.error).
                     setIn(['tasks', 'loading'], false));
    case constants.FETCH_MOD_TASKS_DONE:
        result = pl.result.toOrderedMap().mapEntries(
            ([k, v])=>[v.get('slug'), v]
        );
        return state.set('tasks', Immutable.fromJS({
            error: null,
            loading: false,
            result,
        }));
    case constants.FETCH_MOD_TASK_DONE:
        return state.set('tasks', Immutable.fromJS({
            error: null,
            loading: false,
            result: state.getIn(["tasks", "result"], Immutable.Map())
                         .set(pl.slug, pl.task),
        }));
    case constants.SEND_MOD_TASKS_ACTION_DONE:
        result = pl.result.toMap().mapEntries(([k, v])=>[v.get('slug'), v]);
        result = state.getIn(['tasks', 'result'], Immutable.Map()).merge(result);
        return state.set('tasks', Immutable.fromJS({
            error: null,
            loading: false,
            result,
        }));
    case constants.FETCH_MOD_REVIEWS:
        return state.setIn(['reviews', 'loading'], true)
                    .setIn(['reviews', 'error'], null);
    case constants.FETCH_MOD_REVIEWS_DONE:
        return state.setIn(['reviews', 'loading'], false)
                    .setIn(['reviews', 'results'], pl.result);
    case constants.FETCH_MOD_REVIEWS_ERROR:
        return state.setIn(['reviews', 'loading'], false)
                    .setIn(['reviews', 'error'], pl.error);
    case constants.FETCH_MOD_REVIEW:
        return state.setIn(['fetched_review', 'loading'], true)
                    .setIn(['fetched_review', 'error'], null)
                    .setIn(['fetched_review', 'result'], Immutable.Map());
    case constants.FETCH_MOD_REVIEW_DONE:
        return state.setIn(['fetched_review', 'loading'], false)
                    .setIn(['fetched_review', 'result'], pl.review);
    case constants.FETCH_MOD_REVIEW_ERROR:
        return state.setIn(['fetched_review', 'loading'], false)
                    .setIn(['fetched_review', 'error'], pl.error);
    default:
        return state;
    }
}

export function settings(state=defaultState.SETTINGS, action) {
    const pl = action.payload;
    let result;
    switch (action.type) {
    case constants.FETCH_USER_ACCOUNT_DATA:
    case constants.SAVE_USER_ACCOUNT_DATA:
        return state.set("loading", true);
    case constants.FETCH_USER_ACCOUNT_DATA_DONE:
    case constants.SAVE_USER_ACCOUNT_DATA_DONE:
        return state.set("notifications", pl.data.get('notifications'))
                    .set("subscription_type", pl.data.get('subscription_type'))
                    .set("subscription_begin", pl.data.get('subscription_begin'))
                    .set("subscription_end", pl.data.get('subscription_end'))
                    .set("role", pl.data.get('role'))
                    .set("loading", false)
                    .set("error", null);
    case constants.FETCH_USER_ACCOUNT_DATA_ERROR:
    case constants.SAVE_USER_ACCOUNT_DATA_ERROR:
        return state.set("loading", false)
                    .set("error", pl.error);
    case constants.DELETE_OWN_ACCOUNT_DONE:
        return state.setIn(["termination", "status"], true)
                    .setIn(["termination", "error"], null);
    case constants.DELETE_OWN_ACCOUNT_ERROR:
        return state.setIn(["termination", "status"], false)
                    .setIn(["termination", "error"], getErrorDetail(pl.error));
    case constants.CHANGE_PASSWORD:
        return defaultState.SETTINGS.setIn(["password", "loading"], true);
    case constants.CHANGE_PASSWORD_DONE:
        return state.setIn(["password", "loading"], false)
                    .setIn(["password", "success"], true);
    case constants.CHANGE_PASSWORD_ERROR:
        return state.setIn(["password", "loading"], false)
                    .setIn(["password", "error"], getErrorDetail(pl.error));
    case constants.RESET_USER_ACCOUNT_PASSWORD_DATA:
        return state.set('password', defaultState.SETTINGS.get("password"));
    default:
        return state;
    }
}

export function password_reset(state=defaultState.PASSWORD_RESET, action) {
    const pl = action.payload;
    switch (action.type) {
    case constants.SEND_RESET_PASSWORD_INSTRUCTIONS:
    case constants.SEND_NEW_PASSWORD:
        return state.set('loading', true);
    case constants.SEND_RESET_PASSWORD_INSTRUCTIONS_DONE:
    case constants.SEND_NEW_PASSWORD_DONE:
        return state.set('loading', false).set('message', pl.message);
    case constants.SEND_RESET_PASSWORD_INSTRUCTIONS_ERROR:
    case constants.SEND_NEW_PASSWORD_ERROR:
        const errors = getErrorDetail(pl.error);
        return state.set('loading', false).set('error', errors);
    case constants.CLEAR_RESET_PASSWORD:
        return defaultState.PASSWORD_RESET;
    default:
        return state;
    }
}
