import React, {useEffect} from 'react';
import Immutable from 'immutable';
import {connect, useSelector, useDispatch} from 'react-redux'
import {Route, Switch, useHistory} from "react-router-dom";
import {withRouter} from "react-router";
import LandingPage from './LandingPage';
import LoginPage from './LoginPage';
import LogoutPage from './LogoutPage';
import RegisterPage from './RegisterPage';
import SubscribePage from './SubscribePage';
import EmailConfirmationPage from './EmailConfirmationPage';
import InvitationPage from './InvitationPage';
import ResetPasswordPage from './ResetPasswordPage';
import ProfilePage from './ProfilePage';
import ProfileEditPage from './ProfileEditPage';
import ProfileSearchPage from './ProfileSearchPage';
import ProfileCompletionPage from './ProfileCompletionPage';
import SettingsPage from './SettingsPage';
import GroupsPage from './GroupsPage';
import CustomPage from './CustomPage';
import ChatPage, {NewChatPage} from './ChatPage';
import Header from './Header';
import Footer from './Footer';
import LoadingScreen from './LoadingScreen';
import AdminApp from './admin';
import ModApp from './mods';
import AccountDeletedPage from './AccountDeletedPage';
import {Toast, ModalDialog} from './ui';

import {
   Segment, Container, Ref, Sidebar,
} from "semantic-ui-react";

import {
    clearToast, clearHeadModal, redirectTo, fetchInitialData,
} from "../actions";

import {
    getRedirectUrl, getToastMessages, getModalMessages, appLoaded,
} from "../selectors";

import {isPresent} from "../helpers";

const Redirector = () => {
    const redirectUrl = useSelector(getRedirectUrl);
    const history = useHistory();
    const dispatch = useDispatch();
    useEffect(() => {
        if (isPresent(redirectUrl)) {
            history.push(redirectUrl);
            dispatch(redirectTo(""));
        }
    }, [redirectUrl]);
    return null;
};

const Messages = () => {
    const toasts = useSelector(getToastMessages);
    const modals = useSelector(getModalMessages);
    const dispatch = useDispatch();
    return (
        <>
        <Toast.Container>
            {toasts.map((e, i) =>
                <Toast title={e.get('title')} key={e.get('key')}
                    onClose={() => dispatch(clearToast(e.get('key')))}
                >
                    {e.get('body')}
                </Toast>
            )}
        </Toast.Container>
        {modals.map((e, i) =>
            <ModalDialog title={e.get('title')} key={e.get('key')}
                onClose={() => dispatch(clearHeadModal())}
                actions={e.get('actions', Immutable.List())}
            >
                {e.get('body')}
            </ModalDialog>
        )}
        </>
    );
};

const AppRoutes = () => {
    const isLoaded = useSelector(appLoaded);
    if (!isLoaded) return null;
    return (
        <Switch>
            {/* TODO: check auth and mount only necessary routes */}
            <Route exact path="/" component={LandingPage} />
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/logout" component={LogoutPage} />
            <Route exact path="/register" component={RegisterPage} />
            <Route exact path="/subscribe/:status?" component={SubscribePage} />
            <Route exact path="/byebye" component={AccountDeletedPage} />
            <Route exact path="/profile/edit" component={ProfileEditPage} />
            <Route exact path="/profile/init" component={ProfileCompletionPage} />
            {/* TODO: disallow slugs as edit, create, delete, update, index */}
            <Route exact path="/profile/:slug?" component={ProfilePage} />
            <Route exact path="/chat/:slug?" component={ChatPage} />
            <Route exact path="/chat-to/:slug?" component={NewChatPage} />
            <Route exact path="/p/:slug" component={CustomPage} />
            <Route exact path="/groups/:slug?" component={GroupsPage} />
            <Route exact path="/confirmation" component={EmailConfirmationPage} />
            <Route exact path="/invitation" component={InvitationPage} />
            <Route exact path="/password-reset" component={ResetPasswordPage} />
            <Route path="/settings" component={SettingsPage} />
            <Route path="/search/" component={ProfileSearchPage} />
            <Route path="/admin/" component={AdminApp} />
            <Route path="/mods/" component={ModApp} />
            {/* TODO: Need a page not found route here */}
        </Switch>
    );
};

class App extends React.PureComponent {
    constructor(props) {
        super(props);
        this.contextRef = React.createRef();
        this.state = {
            sidebarVisible: false,
        };
        this.onPusherClick = this.onPusherClick.bind(this);
        this.onSidebarToggle = this.onSidebarToggle.bind(this);
    }

    componentDidMount() {
        const {
            fetchInitialData,
        } = this.props;
        fetchInitialData();
    }

    componentDidUpdate(prevProps) {
        if(this.props.location.pathname !== prevProps.location.pathname) {
            this.onPusherClick();
        }
    }

    onPusherClick() {
        const {sidebarVisible} = this.state;
        if(sidebarVisible){
            this.setState({
                sidebarVisible: false,
            });
        }
    }

    onSidebarToggle(e) {
        this.setState((s, p) => ({
            sidebarVisible: !s.sidebarVisible,
        }));
        e.preventDefault();
    }

    render() {
        return (
            <Ref innerRef={this.contextRef}>
            <div id="app">
                <Sidebar.Pushable className={this.state.sidebarVisible?"open":"closed"}>
                    <Header.Sidebar visible={this.state.sidebarVisible}/>
                    <Sidebar.Pusher
                        dimmed={this.state.sidebarVisible}
                        onClick={this.onPusherClick}
                        style={{minHeight: "100vh"}}
                    >
                        <div className="bgPane">
                            <Header stickyRef={this.contextRef} onSidebarToggle={this.onSidebarToggle} />
                            <div id="page-content">
                                <Segment>
                                    <Container>
                                        <AppRoutes />
                                    </Container>
                                </Segment>
                            </div>
                        </div>
                        <Footer />
                        <Redirector />
                        <Messages />
                        <LoadingScreen />
                    </Sidebar.Pusher>
                </Sidebar.Pushable>
            </div>
            </Ref>
        );
    }
}

App.defaultProps = {};
App.propTypes = {
};

const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = {
    fetchInitialData,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
